<template>
    <div class="txt-clr5 b-clr1">
      <router-link :to="{name: 'HomeCategory', params: {id:category.RowKey}}" class="packlink">
        <div class="pack" v-bind:style='{ backgroundImage: "url(" + picture + ")", }'>
          <div v-if="category.title" :class="titleclass" >{{category.title}}</div>
          <!-- <img :src="picture"/> -->
          
        </div>
      </router-link>
      <div :class="{'messages':true, 'b-clr5':even, 'txt-clr1':even, 'b-clr1': !even, 'txt-clr5': !even}">
          <div v-if="category.shortdescription" class="categoryshortdesc">{{category.shortdescription}}</div>
          <div v-if="category.longdescription" class="categorylongdesc">{{category.longdescription}}</div>
          <div v-if="category.definition" class="categorydefinition">{{category.definition}}</div> 

          <roundedbtn :dark="true" :size="'big'" :to="{name: 'HomeCategory', params: {id:category.RowKey}}" class="linkbutton">
            Découvrir
          </roundedbtn>
          <div v-if="hasEdit">
            <roundedbtn :dark="true" size="normal" :to="{name: 'EditCategory', params: {name:category.RowKey}}">Editer</roundedbtn>
          </div>
      </div>
    </div>
</template>
<script>

import tool from "../assets/js/tool.js";
import roundedbtn from "../microcomponents/roundedbutton.vue";

export default {
    props: {
        category: Object,
        hasEdit: Boolean,
        even: Boolean
    },
    computed:{
        picture: function(){
            return tool.getUrl('/api/image/'+this.category.pictureUrl);
        },
        titleclass: function(){
          return this.category.isdarktitle ? "categorytitle txt-clr5":"categorytitle txt-clr1"
        }
    },
    components:{
      roundedbtn
    }
    
}
</script>
<style scoped>
.linkbutton {
    position:absolute;
    bottom:20px;
    right:20px;
}



    .pack{
        width: 100%;  
        height:450px;
        background-size: cover; 

    }

    .packlink{
        margin:0px;
        padding:0px;
        display: table-cell;
        width: 50%;  
        height:450px;
        background-size: cover; 
        text-decoration: none;

    }

    .categorytitle:hover {
      font-size:36px;
      transition-duration:0.4s;
      z-index:5;
      position:relative;
      top:0px;
      /* overflow: visible; */
    }

    .messages{
       display: table-cell;
       width:50%;
       vertical-align: top;
       padding-left:20px;
       padding-right:20px;
       position:relative;
       min-height:450px;
    }

    .categorytitle{
      font-size: 32px;
      font-weight: 900;
      font-style: normal;
      text-align: center;
      vertical-align: middle;
      /* position:relative; */
      z-index:0;
      padding-top:200px;
    }

    .categoryshortdesc{
      font-size:24pt;
      font-style: normal;

      text-decoration: none;
      padding-bottom: 20px;
      padding-top: 20px;
      text-align: justify;
      text-align-last: center;
    }

    .categorylongdesc{
      font-size: 24pt;
      font-weight: bold;
      font-style: normal;
      text-decoration: none;
      padding-top: 20px;
      padding-bottom: 20px;


    }

    .categorydefinition {
      font-size: 18pt;
      font-weight: normal;
      font-style: italic;
      text-decoration: none;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media screen and (max-width: 1200px) and (min-width: 769px){
      .categorytitle{
          font-size: 20pt;
        }  
      
      .categoryshortdesc{
          font-size:20pt;
          /* font-weight: bold; */
      }
      .categorylongdesc{
          font-size: 20pt;
          font-weight: normal;
        }

      .categorydefinition {
        font-size: 16pt;
        padding-bottom : 100px;
      }
      .packlink{
        min-height:450px;
        background-size: cover;
        background-position: center;
      }
      .pack {
        min-height:450px;
        background-size: cover;
        background-position: center;
        height:100%;
      }
    }

    @media screen and (max-width: 768px){
      .packlink,
      .pack{
        width:100%;
        display:block;
        height:250px;
      }
      .messages{
        width:100%;

        display:block;
        padding-left:0px;
        padding-right:0px;
        height:auto;
      }

      .categorytitle{
          font-size: 20pt;
          padding-top:100px;
        }
      .categoryshortdesc{
          font-size:18pt;
          /* font-weight: bold; */
          padding-right:15px;
          padding-left:15px;
      }
      .categorylongdesc{
          font-size: 16pt;
          font-weight: normal;
          padding-right:10px;
          padding-left:10px;
        }

      .categorydefinition {
        font-size: 15pt;
        padding:20px;
        padding-bottom : 100px;
      }

      .linkbutton {
        bottom:6px;
        right:20px;
    }
    }
</style>