<template>
    <div class="cartouchecontainer">
        <div class="top">
            <div class="firsttext">{{ccartouche.firsttext}}</div>
            <div class="secondtext">{{ccartouche.secondtext}}</div>
            <div class="ruler"><hr></div>

            <div class="thirdtext">{{ccartouche.thirdtext}}</div>
            <div v-if="centerImageDisplayed" class="centerimage">
                <img v-bind:src="centerImage">
            </div>
            <div class="maintext">
                <div class="line1">{{ccartouche.maintextline1}}</div>
                <div class="line2">{{ccartouche.maintextline2}}</div>
            </div>
        </div>
        <div class="bottom">Back To Relaxation <img src="/logo.jpg"></div>
    </div>
    
</template>

<script>
import tool from "../assets/js/tool.js"
export default {
    props:{ 
        cartouche:{
            type:Object
        },
        coaching:{
            type:Object
        }
    },
    computed:{
        centerImage:function(){
            return tool.getUrl('/api/image/'+this.ccartouche.img);
        },
        centerImageDisplayed: function(){
            return this.ccartouche.img !='';
        },
        ccartouche: function(){
            if(this.cartouche) {
                return this.cartouche;
            }else {
                return {
                    firsttext:this.coaching.cartouchefirsttext,
                    secondtext:this.coaching.cartouchesecondtext,
                    thirdtext:this.coaching.cartouchethirdtext,
                    img:this.coaching.cartoucheimg,
                    maintextline1:this.coaching.cartouchemaintextline1,
                    maintextline2:this.coaching.cartouchemaintextline2
                }
            }
        }
    }
}
</script>
<style scoped>
.cartouchecontainer {
    height:100%;
    width:170px;
    background-color:#FFE699;
    padding-right: 2px;
    padding-left:2px;
    padding-top:2px;
    padding-bottom: 2px;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    display: flex;
    flex-direction: column;
}
.top {
    flex:1;
    order:1;
}
.firsttext {
    background-color:black;
    color:white;
    text-align: center;
    border-radius: 5px;
}
.secondtext {
    text-align: center;
    font-size:19px;
    font-weight: bold;
}
.ruler {
    margin-top:12px;
    padding-right:40px;
    padding-left:40px;
}
.ruler hr {
    color:black;
    height:1px;
    background-color:black;
}
.thirdtext {
    font-weight:bolder;
    font-size:22px;
    width:100%;
    text-align: center;
}
.centerimage {
    width:fit-content;
    margin-left:auto;
    margin-right:auto;
}
.centerimage img{
    height:48px;
}

.maintext {
    color:white;
    background-color:black;
    margin-left:1px;
    margin-right:1px;
    text-align: center;
    font-weight: bold;
}

.maintext .line1{
    font-size:32px;
}
.maintext .line2{
    font-size:22px;
}

.bottom {
    /* position:relative;
    bottom:-15px; */
    color:white;
    background-color:black;
    border-radius:5px;
    order:2;
    margin-bottom:7px;
}
.bottom img {
    height:17px;
    width:17px;
    float:right;
    margin:2px;
}
</style>