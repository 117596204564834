<template>
    <div :class="menuclass">
       <burgermenu @burgerclick="switchDisplay" :isMain="isMain" :opened="isOpen">
            <slot></slot>
        </burgermenu>
        <div :class="menudivclass">
            <verticalmenuitem v-for="anitem in menus" :menuitem="anitem" v-bind:key="anitem.label" v-on:navdone="closeDisplay()"/>
        </div>
    </div>
</template>

<script>
import burgermenu from "./burgermenu.vue";
import verticalmenuitem from "./verticalmenuitem.vue"

export default {
    props : {
        menus:Array,
        isMain:Boolean
    },
    components: {
        burgermenu,
        verticalmenuitem
    },
    data: function () {
        return {
            isOpen: false
        }
    },
    methods:{
        switchDisplay: function(lisOpen){
            this.isOpen = lisOpen;
        },
        closeDisplay:function(){
            this.isOpen = false;
        }
    },
    computed:{
        menudivclass: function(){
            var zindexclass = this.isMain ? "above" : "below"
            return (this.isOpen ? "verticalmenuopeneddiv b-clr5 " : "verticalmenudiv b-clr5 ")+zindexclass;
        },
        menuclass:function(){
            return this.isMain ? "verticalmenu" : "verticalmenu below";
        }
    }
}
</script>

<style scoped>

.above {
    position: relative;
    z-index:100;
}

.below {
    z-index:1;
}

.verticalmenu {
    display: block;
}

.verticalmenudiv,
.verticalmenuopeneddiv{
    /* position: relative; Stay in place */
    top: 0;
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    /* padding-top: 48px; Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
    /* opacity:0.9 */
}
.verticalmenudiv{
    width:0px;
    height:0px;
}
.verticalmenuopeneddiv{
    width:100%;
    height:auto;
}

@media screen and (min-width: 1200px){
    .verticalmenu {
        display: none;
}
}

</style>