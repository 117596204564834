<template>
  <div>
        <div class="row">
            <div class="col-25">
                <label for="fsource">source</label>
            </div>
            <div class="col-75">
                <textarea id="fsource" v-model="content.source" rows="10"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fbackground">BackGround</label>
            </div>
            <div class="col-75">
                <input id="fbackground" v-model="content.hasBackground" type="checkbox"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label>Aperçu</label>
            </div>
            <div class="col-75">
                <htmview :content="content" />
            </div>
        </div>
  </div>
</template>

<script>
import htmview from './DynamicHtmlView.vue';

export default {
    props:{
        content: {
            type:Object
        }
    },
    components:{
        htmview
    }
}
</script>

<style scoped>


</style>