<template>
  <div class="submenu">
    <a v-if="this.menuitem.ref" :href="this.menuitem.ref" class="txt-clr1 submenulink">
        {{this.menuitem.label}}
    </a>
    <a v-else-if="this.menuitem.to" v-on:click="goto(menuitem.to)" class="txt-clr1 submenulink">
        {{this.menuitem.label}}
    </a>
  </div>
</template>

<script>
export default {
    props:{
        menuitem:Object
    },
    emits: [
        "clicked"
    ],
    methods:{
        generateto:function(orgto){
            if (typeof orgto === "string" ){
              return {name:orgto};
            } else {
                return orgto;
            }
        },
        goto:function(navto){
            this.$emit("clicked");
            this.$router.push(this.generateto(navto));
        }
    }

}
</script>

<style scoped>
.submenu{
    display: block;
    padding:5px;
}

.submenu:hover {
    background-color: #375f1b;
}

.submenulink {
    display: block;
    text-decoration: none;
}
</style>