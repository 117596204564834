<template>
    <div>
        <div :class="{'coachingmini':true, 'b-clr5':even, 'txt-clr1':even, 'b-clr1': !even, 'txt-clr5': !even}" >
            <div class="sgauchemini" >
                <img :src="smallpicture" v-on:click="view()"/>
            </div>
            <div class="scentremini">
                <div class="title">{{coaching.title}}</div>
                
                <div class="shortdesc" v-html="coaching.shortdescription"></div>
            </div>
            <roundedbutton :dark="true" v-on:clicked="view()" :size="'big'" class="linkbutton">Commencer</roundedbutton>
        </div>
    </div>
</template>

<script>
import tool from "../assets/js/tool.js";
import roundedbutton from "../microcomponents/roundedbutton.vue";

export default {
    props:{
        coaching: {
            type:Object
        },
        user: {
            type: Object
        },
        even: {
            type:Boolean
        }
    },
    computed:{
        smallpicture: function(){
            return tool.getUrl('/api/image/'+this.coaching.smallpictureUrl);
        }
    },
    methods: {
        view: function(){
            let cid = this.coaching.RowKey;
            this.$router.push(
                {
                    name:'MyCoaching',
                    params: { 
                        id:cid
                    }
                }
            );
        }
    },
    components:{
        roundedbutton
    }
}
</script>

<style scoped>
    .coachingmini {
        display:block;
        width:100%;
        margin:0px;
        padding:0px;
        border:0px;
        position: relative;
    }

    .sgauchemini {
        display:inline-block;
        width: 40%;
        margin:0px;
        padding:0px;
    }

    .sgauchemini img {
        display:inline-block;
        width:100%;
        margin:0px;
        padding:0px;
        vertical-align: top;
        cursor:pointer
 
    }


    .scentremini {
        display:inline-block;
        width: 60%;
        vertical-align: top;
        overflow: hidden;
        padding-bottom:80px;
       
    }

    .title{
        font-size: 23px;
        padding:10px;
        
    }
    .shortdesc{
        font-size: 18px;
        font-style: italic;
        padding-left:10px;
    }

    .linkbutton {
        position:absolute;
        bottom:20px;
        right:20px;
    }
    @media screen and (max-width: 1200px){

    }

     @media screen and (max-width: 768px){
        .coachingmini
         {
            display: block;
            width:100%;
        }
        .sgauchemini {
            display:block;
            width:100%;
        }

     }

</style>