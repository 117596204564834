<template>
    <div class="viewcontainer">
        <nav  v-if="!coaching.useTabForVideo" id="navbar" class="sidebar b-clr5">
            <a href="#INTRO" class="bar-item padding-navlarge b-clr5 txt-clr1 htxt-clr0 hb-clr2">
                <div>INTRO</div>
                <i class="fa fa-home large-text"/>
            </a>
            <a v-for="vid in videos" v-bind:key="vid.position" v-bind:href="'#VIDEO'+vid.position" class="bar-item padding-navlarge b-clr5 txt-clr0 htxt-clr0 hb-clr2">
                 <div>VIDEO</div>
                 <span class="large-text">{{vid.position}}</span>
            </a>
            <a v-on:click="$emit('close',$event.target.value)" class="bar-item padding-navlarge b-clr5 txt-clr0 htxt-clr0 hb-clr2">
                <div>FERMER</div>
                <i class="fa fa-close large-text"/>
            </a>
        </nav>
        <div :class="calcmainclass" id="mainview">

            <div id="INTRO" class="b-clr1 txt-clr5">
                <div class="title txt-clr1 b-clr5">
                    {{coaching.title}}
                </div>
                <div class="vmenucontainer" v-if="coaching.useTabForVideo">
                    <verticalmenu :menus="this.calcmenuvideo">
                        <i class="fa fa-solid fa-film"></i>&nbsp;Mes Vidéos
                    </verticalmenu>
                </div>
                <div class="hmenucontainer b-clr4" v-if="coaching.useTabForVideo" >
                    <horizontalmenu :menus="this.calcmenuvideo" class="b-clr4" :isOpaque="true">
                    </horizontalmenu>
                </div>
                <div class="intro" v-html="coaching.introduction" v-if="coaching.introduction">
                </div>
                <div class="centeredimage">
                    <img :src="coachinglargeImage" class="centeredimage"/>
                </div>
            </div>
            
            <div v-if="coaching.useTabForVideo">
                
                <videoview v-bind:video="this.currentvideo"/>
                <div class="buttoncontainer">
                    <div class="centeredbuttoncontainer">
                        <roundedbutton v-if="this.previd" :to="this.prevrt" :dark="true">
                            <i class="fa fa-arrow-left"/><span class="previdtext">Précédente</span>
                        </roundedbutton>
                        <roundedbutton v-if="this.nextvid" :to="nextrt" :dark="true">
                            <span class="nextvidtext">Suivante</span><i class="fa fa-arrow-right"/>
                        </roundedbutton>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="vid in videos" v-bind:key="vid.position" v-bind:id="'VIDEO'+vid.position">
                    <videoview v-bind:video="vid"/>
                </div>
            </div>
            <dynamicContent :dynamicContentList="coaching.dynamicContent" v-if="coaching.dynamicContent"/>
            <div class="conclusion b-clr4 txt-clr2" v-if="coaching.footer">
                <div v-html="coaching.footer"></div>
            </div>
            <div class="closebottom padding-navlarge  b-clr1 txt-clr3 htxt-clr0 hb-clr2"> 
                <a v-on:click="$emit('close',$event.target.value)">
                    <i class="fa fa-close large-text"/><span class="closetext">FERMER</span>
                
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import tool from "../assets/js/tool.js";
import videoview from "./VideoView.vue";
import roundedbutton from "../microcomponents/roundedbutton.vue";
import dynamicContent from "./DynamicContentListView.vue";
import horizontalmenu from "../microcomponents/horizontalmenu.vue";
import verticalmenu from "../microcomponents/verticalmenu.vue";

export default {
    data: function(){
        return {
            videos:[],
            mainview:null,
            navBar:null,
            originOffset:0,
            currentvideo:null,
            prevideo:null,
            nextvideo:null,
            videomenuitem: []           
        }
    },
    methods:{
        getImageUrl(url){
            return tool.getUrl("/api/image/"+url);
        },
        handleScroll(){
            if (this.navbar){
                let rect = this.mainview.getBoundingClientRect();
                if (rect.top > 0){          
                    this.navBar.style.position=null;
                } else {
                    this.navBar.style.position="absolute";
                    let offset = -rect.top+this.originOffset;
                    this.navBar.style.top = offset+"px";
                }
            }
        },
        getvideoroute(avideo){
            let rt = {
                name:'MyCoaching',
                params : {
                    id: avideo.coachId,
                    chapter: this.normalize(avideo.tabgroup),
                    video: this.normalize(avideo.tablabel)
                }
            }
            return rt;
        },
        refreshVideo(){
            let chapterroute = this.$route.params.chapter;
            let videoroute = this.$route.params.video;
            if(this.coaching.useTabForVideo){
                if(chapterroute == null || videoroute == null){
                    this.currentvideo = this.videos[0];
                    chapterroute = this.normalize(this.currentvideo.tabgroup);
                    videoroute = this.normalize(this.currentvideo.tablabel);
                    this.$router.push({
                        name:'MyCoaching',
                        params : {
                            id: this.coaching.RowKey,
                            chapter: chapterroute,
                            video: videoroute
                        }
                    });
                } else {
                    let menuhash = {};
                    for(let vidindexstr in this.videos){
                        let vidindex = Number.parseInt(vidindexstr);
                        let vid = this.videos[vidindex];
                        let itemtabgroup = this.normalize(vid.tabgroup);
                        let itemtablabel = this.normalize(vid.tablabel);
                        if (chapterroute == itemtabgroup
                            && videoroute == itemtablabel){
                                this.currentvideo = vid;
                                if(vidindex>0){
                                    this.prevideo = this.videos[vidindex-1];
                                }else {
                                    this.prevideo = null;
                                }
                                if(vidindex==this.videos.length){
                                    this.nextvideo = null;
                                }else{
                                    this.nextvideo = this.videos[vidindex+1];
                                }
                        }

                        let submenuitem = {
                            to : {
                                name:'MyCoaching',
                                params : {
                                    id: this.coaching.RowKey,
                                    chapter: itemtabgroup,
                                    video: itemtablabel
                                }
                            },
                            label : vid.tablabel
                        }
                        if (!menuhash[itemtabgroup]){
                            menuhash[itemtabgroup] = {
                                label: vid.tabgroup,
                                submenu: [submenuitem]
                            }
                        }else{
                            menuhash[itemtabgroup].submenu.push(submenuitem);
                        }
                    }
                    this.videomenuitem=[];
                    for(let menu in menuhash){
                        let menuvalue = menuhash[menu];
                        if( menuvalue.submenu.length == 1 ){
                            menuvalue.to = menuvalue.submenu[0].to;
                            menuvalue.submenu = null;
                        }
                        this.videomenuitem.push(menuvalue);
                    }
                }
            }
        },
        normalize(label){
            return label.toLowerCase().replaceAll(' ','');
        }
    },
    computed:{
        coachinglargeImage:function(){
            return this.getImageUrl(this.coaching.pictureUrl);
        },
        nextvid:function(){
            return this.nextvideo;
        },
        previd:function(){
            return this.prevideo;
        },
        prevrt:function(){
            let prt = this.getvideoroute(this.previd);
            return prt;
        },
        nextrt:function(){
            let nrt=this.getvideoroute(this.nextvid);
            return nrt;
        },
        calcmainclass: function(){
            return this.coaching && this.coaching.useTabForVideo ? "padding-large": "padding-large main";
        },
        calcmenuvideo: function(){
            return this.videomenuitem;
        }

    },
    emits: [
        "close"
    ],
    props:{
        coaching: {
            type:Object
        },
        user: {
            type: Object
        }
    },
    created:function(){
        let self = this;
        const coachId = this.coaching.RowKey;
        fetch(tool.getUrl("/api/videometa/"+coachId))
        .then(
            response => response.json()
        ).then(
            data => {
                self.videos = data.sort(tool.sortByPosition);
                self.videos.forEach(element => {
                    element.coachId = coachId
                });
                self.refreshVideo();
            }
        );
        window.addEventListener('scroll', this.handleScroll);

        
    },
    mounted:function(){
        this.mainview = document.getElementById("mainview");
        this.navBar = document.getElementById("navbar");
        let winrect = document.body.getBoundingClientRect();
        let rect = this.mainview.getBoundingClientRect();
        this.originOffset = rect.top - winrect.top -250;
    },
    destroyed:function(){
        window.removeEventListener('scroll', this.handleScroll);
    },
    components:{
        videoview,
        dynamicContent,
        roundedbutton,
        horizontalmenu,
        verticalmenu
    },
    watch: {
        // call again the method if the route changes
        '$route': 'refreshVideo'
    },
}
</script>

<style scoped>
.hmenucontainer {
    width:100%;
    padding-left: 10px;
    padding-right: 38px;
    padding-top:0px;
    padding-bottom:0px;

}
.vmenucontainer{
    width:auto;
}
.sidebar {
    width:120px;
    text-align: center;
    text-decoration: none;
    /* position: sticky; */
    float:left;
    z-index:100;
}

.bar-item{
    width:100%;
    display:block;
    border:none;
    overflow: hidden;
    cursor:pointer;
    text-decoration: none;
}
.padding-navlarge {
    padding-top: 6px ;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
}
.large-text {
    font-size: 42px;
    vertical-align: middle;
}

.main {
    margin-left: 144px;
}

.title {
    text-align:center;
    vertical-align: middle;
    padding:24px;
    margin:0px auto;
    width:100%;
    font-size: 40px;
    /* box-shadow:0 4px 20px 0; */
}


.intro {
    padding:24px;
    margin:24px auto;
    width:90%;
    text-align: center;
}
.bandeau {
    height:263px;
    /* width:100%; */
    background-position: center;
    background-size: cover;
    overflow:hidden;
}

div.centeredimage {
    height:263px;
    width:384px;
    margin-right:auto;
    margin-left:auto;
}

img.centeredimage {
    height:263px;
    width:384px;
}

.conclusion{
    padding:24px;
}
.closebottom{
    display:none;
    text-align: center;
    margin-top:10px;
    
}

.buttoncontainer{
    width:100%;
    padding-bottom:15px;
}

.centeredbuttoncontainer {
    width:fit-content;
    margin-left: auto;
    margin-right: auto;
}

.previdtext{
    margin-left: 5px;
}

.nextvidtext {
    margin-right: 5px;
}

.closebottom .closetext{
    font-size:26px;
    padding-left:20px;
    vertical-align: middle;
}

@media screen and (max-width: 1150px){
    .sidebar {
        display:none;
    }
    .main {
        margin-left: 0px;
    }
    .closebottom{
        display: block;
    }
}
@media screen and (max-width: 700px) and (min-width: 450px){
    .title {
        padding:12px;
        margin:0px auto;
        /* width:350px; */
        font-size: 30px;
    }
}
@media screen and (max-width: 450px) {
    .title {
        padding:8px;
        margin:0px auto;
        /* width:200px; */
        font-size: 25px;
    }
}

</style>