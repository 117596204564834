<template>
  <div class="autocomplete">
      <input type="text" name="blob" v-bind:value="this.value" v-on:change="changeval($event.target.value)" :placeholder="placeholder" v-on:keyup="keypressed($event)" autocomplete="off"/>
      <div v-if="this.isListDisplayed" class="autocomplete-items">
          <div v-for="(blob,index) in this.bloblist" v-bind:key="blob">
              <div v-on:click="selectval(blob)" v-bind:class="calculatedItemClass(index)">{{blob}}</div>
          </div>
      </div>
  </div>
</template>

<script>
import tool from  "../assets/js/tool.js";

export default {    
    data: function(){
        return{
            internalIsListDisplayed : false,
            internalBlobList:[],
            currentFocus : -1
        }
    },
    props:{
        type: String,
        value: String,
        placeholder: String
    },
    methods:{
        changeval: function(val){
            if(val.length > 0){
                this.internalIsListDisplayed = true;
            }
            
            this.$emit('input', val);
        },
        selectval: function(val){
            this.internalIsListDisplayed = false;           
            this.$emit('input', val);
        },
        keypressed: function(event){
            var val = event.target.value;
            
            if (val.length>0){
                this.getBloblist(val);
                this.internalIsListDisplayed = true;
            }
            if (event.keyCode==40){
                // key down is pressed
                if(this.currentFocus < this.bloblist.length){
                    this.currentFocus++;
                }
                 this.$emit('input', val);
            } else if (event.keyCode==38){
                // key up is pressed
                if(this.currentFocus > 0){
                    this.currentFocus--;
                }
                 this.$emit('input', val);

            }else if(event.keyCode==13) {
                // enter is pressed
                if(this.currentFocus>=0 && this.currentFocus<this.bloblist.length){
                    val = this.bloblist[this.currentFocus];
                    
                    this.$emit('input', val);
                    this.currentFocus=-1;
                    this.internalIsListDisplayed = false;
                }
            }else {
                this.$emit('input', val);
            }
        },
        calculatedItemClass: function(index){
            var dispclass = index==this.currentFocus? 'txt-clr1 b-clr3 hb-clr4':'txt-clr3 b-clr1 hb-clr4';
            return dispclass;
        },
        getBloblist: async function(val){
            var blobListWrapper = await tool.getBlobList(this.type, val);
            this.internalBlobList = blobListWrapper.bloblist;
        }
    },
    computed: {
        bloblist : function(){
            if(this.value.length >0){
                var blobList = this.internalBlobList;
                return blobList;
            } else {
                return [];
            }
        },
        isListDisplayed: function(){
            return this.internalIsListDisplayed;
        }
    },
    emits:[
        "input"
    ]
}
</script>

<style scoped>
.autocomplete {
  /*the container must be positioned relative:*/
   position: relative;

}
input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
    background-color: #f1f1f1;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #ccc;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div div {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #d4d4d4;
}

</style>