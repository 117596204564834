<template>
    <video v-bind:width="width" v-bind:height="height" controls v-bind:src="videostream" v-bind:poster="vidpicture">
                    
    </video>
</template>

<script>
import tool from "../assets/js/tool.js"

export default {
    data: function(){
        return {
            calculatedVideoUrl:""
        }
    },
    props:{
        video: {
            type:Object
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        }
    },
    computed:{
        vidpicture: function(){
            return tool.getUrl('/api/image/'+this.video.videoPictureUrl);
        },
        videostream:function(){
            return this.calculatedVideoUrl;
        }
    },
    methods:{
        newVideo:function(){
            var path = '/api/video/'+this.video.videourl;
            if(this.video.coachId){
                path+='/'+this.video.coachId
            }
            var url = tool.getUrl(path);
            fetch(url).then(
                response => response.json()
            ).then(
                data => {
                    if(data){
                        this.calculatedVideoUrl = data.url;
                    }
                }
            );
        }
    },
    created: function(){
        this.newVideo();
    }
}
</script>