<template>
    <div>
        <div v-html="content.source" v-bind:class="this.class"/>
    </div>
</template>

<script>
export default {
    props:{
        content: {
            type:Object
        }
    },
    computed:{

        class: function(){
            let aclass = "";
            if(this.content.hasBackground){
                aclass +=" b-clr2";
            }
            return aclass;
        }
    }
}
</script>

<style>

</style>