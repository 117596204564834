<template>
  <div class="loginmenu">
    <div v-if="user" v-on:click="switchopen" class="profilebutton txt-clr1 htxt-clr3">
        <i class="fa fa-user-circle-o fa-fw"></i>
    </div>
    <div class="loginsubmenu txt-clr1 b-clr5" v-if="compIsOpened">
        <div class="loginsubmenuitem">
            <span v-if="user.identityProvider == 'google'" class="google">
                <i class="fa fa-google fa-fw"></i> {{ userDetails }}
            </span>
            <span v-if="user.identityProvider == 'aad'" class="windows">
                <i class="fa fa-windows fa-fw"></i> {{ userDetails }}
            </span>
            <span v-if="user.identityProvider == 'twitter'" class="twitter">
                <i class="fa fa-twitter fa-fw"></i> {{ userDetails }}
            </span>
            <span v-if="user.identityProvider == 'facebook'" class="fb">
                <i class="fa fa-facebook fa-fw"></i> {{ userDetails }}
            </span>
        </div>
        <div class="loginsubmenuitem hb-clr4">
            <a  v-if="user" class="txt-clr0" :href="'/.auth/logout?post_logout_redirect_uri=' + postlogouturl">
                <i class="fa fa-sign-out fa-fw"></i>Se d&eacute;connecter
            </a>
        </div>
    </div>
  </div>
</template>

<script>
import tool from "../assets/js/tool.js";
// import roundedbtn from "../microcomponents/roundedbutton.vue";

export default {
    data: function () {
        return {
            isOpened:false
        }
    },
    props: {
        user: Object
        
    },
    computed: {
        postlogouturl: function () {
            return window.location.origin;
        },
        userDetails: function () {
            return tool.getUserDetails(this.user);
        },
        compIsOpened: function(){
            return this.isOpened;
        }
    },
    components: {
        // roundedbtn
    },
    methods:{
        switchopen : function(){
            this.isOpened = !this.isOpened;
        }
    }
}
</script>

<style scoped>
.profilebutton {
    display: inline;
    font-size: 26px;
    position:relative;
    top:3px;
    cursor:pointer;
}


.loginmenu {
    display: inline;
}
.loginsubmenu{
    display:block;
    position:absolute;
    z-index: 2;
    top:45px;
    right:0px;
    min-width:300px;
    opacity: 0.8;
}
.loginsubmenuitem {
    width:100%;
    vertical-align: middle;
    padding: 12px 12px;
}
.loginsubmenuitem a {
    text-decoration: none;
}
</style>