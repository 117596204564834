<template>
    <roundedbutton :dark="true" :to="'https://calendly.com/sandrine-sage'">
        Réserver en ligne
    </roundedbutton>
</template>
<script>
import roundedbutton from '../microcomponents/roundedbutton.vue';

export default {
    components: {
        roundedbutton
    }
}

</script>