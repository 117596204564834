<template>
    <div>
        <div class="breadcrumb b-clr1 txt-clr4">
            <router-link :to="{name: 'Home'}" class="txt-clr4 htxt-clr3">
                <i class="fa fa-home"/> Boutique
            </router-link>
            <i class="fa fa-caret-right"></i>
            {{currentcategory ? currentcategory.title : ""}}
        </div>

        <coachinglist v-bind:coachinglist="displayedcoachings" >
        </coachinglist>
        <loader v-bind:loaderenabled="loaderenabled"/>
    </div>
</template>
<script>

import tool from "../assets/js/tool.js";
import coachinglist from "../components/CoachingList.vue";
import loader from '../components/Loader.vue';

export default {
    data: function(){
        return {

            availablecoachings:[], 
            currentcategory:null,
            loaderenabled: true,
        }
    },
    components: {
        coachinglist,
        loader
    },
    methods : {
        fetchCoachings: async function(){ 
            this.loaderenabled= true;
            let currentcategoryid = this.$route.params.id;
            if (this.currentcategory){
                this.availablecoachings = await tool.getCoachings(currentcategoryid);
            }else {
                this.availablecoachings = [];
            }
            this.loaderenabled= false;
        },
        fetchcategory: async function(){
            if(this.$route.name=='HomeCategory'){
                let currentcategoryid = this.$route.params.id;
                this.currentcategory = await tool.getCategory(currentcategoryid);
                if (this.currentcategory){
                    tool.settitle(this.pageTitle());
                    tool.setmetadescription(this.currentcategory.seodesc);
                    tool.setthumbnail(this.currentcategory.seothumbnail);
                    await tool.setcanonicalpagelink('/stop/'+currentcategoryid);
                }else {
                    this.$router.push({name:'Home'});
                }
            }
        },
        reloadAll: async function(){
            await this.fetchcategory();
            await this.fetchCoachings();
        },
        pageTitle: function(){
            let tit = 'Coaching Back To Relaxation';
            if (this.currentcategory){
                tit =' | ' +tit;
                if(this.currentcategory.pagetitle){
                    tit=this.currentcategory.pagetitle + tit;
                }else if (this.currentcategory.title){
                    tit=this.currentcategory.title+tit;
                }
            }
            return tit;
        } 

    },
    computed: {

        displayedcoachings:function(){
            if(this.$route.name=='HomeCategory'){
                let currentcategory = this.$route.params.id;
                let filteredCoachings = this.availablecoachings.filter(a => a.category==currentcategory).sort(tool.sortByPosition);
                return filteredCoachings;
            }else {
                return this.availablecoachings;
            }
        }
    },
    created : function(){
        this.reloadAll();
    },
    watch: {
        // call again the method if the route changes
        '$route': 'reloadAll'
    },
}
</script>

<style scoped>
    .breadcrumb {
        margin-top: 5px;
        /* margin-bottom: 10px; */
        padding: 3px;
        font-size: 16px;
        text-decoration: none;
        font-weight:600;
    }

    .breadcrumb a {
        text-decoration: none;
        padding-right:5px;
        padding-left: 5px;
    }
</style>