<template>
    <div class="onerow"> 
        <div v-for="(group1, groupIndex1) in filteredcoachingsgroups" v-bind:key="'largegp'+groupIndex1" class="column group">
            <div v-for="(coaching1, index1) in group1" v-bind:key="'large'+index1">
                <coachingextract v-bind:coaching="coaching1"></coachingextract>
            </div>
        </div>
        <div v-for="(group2, groupIndex2) in filteredcoachingspairs" v-bind:key="'medgp'+groupIndex2" class="column pair">
            <div v-for="(coaching2, index2) in group2" v-bind:key="'med'+index2">
                <coachingextract v-bind:coaching="coaching2"></coachingextract>
            </div>
        </div>
        <div v-for="(group3, groupIndex3) in filteredcoachingsSingle" v-bind:key="'smallgp'+groupIndex3" class="column single">
            <div v-for="(coaching3, index3) in group3" v-bind:key="'small'+index3">
                <coachingextract v-bind:coaching="coaching3"></coachingextract>
            </div>
        </div>
    </div>
</template>

<script>
import tool from "../assets/js/tool.js";
import coachingextract from "./CoachingExtract.vue";

export default {
    data: function(){
        return {
            
        }
    },
    props:{
        coachinglist: {
            type:Array
        },
        category:{
            type:String
        }
    },
    components: {
        coachingextract
    },
    methods:{
        filteredCoachingsMethod: function(){
            var filteredcoachings;
            if(this.category && this.coachinglist){
                filteredcoachings = this.coachinglist.filter(a => a.category == this.category).sort(tool.sortByPosition);
            }else {
                filteredcoachings = this.coachinglist;
            }
            return filteredcoachings;
        },
        filteredcoachingsgroupsMethod: function(colNb){
            var groups = [];
            var coachingArray =  this.filteredCoachingsMethod();
            
            for(let index=0; index<coachingArray.length; index++){
                let coaching = coachingArray[index];
                let groupIndex = index % colNb;
                if (groups[groupIndex]==null){
                    groups[groupIndex] = [];
                }
                groups[groupIndex].push(coaching);
            }
            return groups;
        }
    },
    computed: {
        filteredCoachings: function(){
            return this.filteredCoachingsMethod();
        },
        filteredcoachingsgroups: function(){
            return this.filteredcoachingsgroupsMethod(4);           
        },
        filteredcoachingspairs: function(){
            return this.filteredcoachingsgroupsMethod(2);           
        },
        filteredcoachingsSingle: function(){
            return this.filteredcoachingsgroupsMethod(1);           
        }
    }
}
</script>

<style scoped>
.onerow {
  display: flex;
  flex-wrap: wrap;
}
.column {
  flex: 25%;
  max-width: 25%;
}
.group {
    display: block
}

.pair {
    display: none;
}

.single {
    display: none; 
}


@media screen and (max-width: 1200px) and (min-width: 769px){
    .column {
        flex: 50%;
        max-width: 50%;
    }

    .group {
        display: none
    }

    .pair {
        display: block;
    }

    .single {
        display: none; 
    }
}

@media screen and (max-width: 768px){
    .column {
        flex: 100%;
        max-width: 100%;
    }
    .group {
        display: none
    }

    .pair {
        display: none;
    }

    .single {
        display: block; 
    }
}
</style>