<template>
    <div>
        <paypalinit v-on:initdone="paypalisinitialized=true"></paypalinit>

        <div class="breadcrumb b-clr1 txt-clr4">
            <router-link :to="{name: 'Home'}" class="txt-clr4 htxt-clr3">
                <i class="fa fa-home"/> Boutique
            </router-link>
            <i class="fa fa-caret-right"></i>
            <router-link :to="{name: 'HomeCategory', params: {id:currentcategory?currentcategory.RowKey:''}}" class="txt-clr4 htxt-clr3">
                {{categorytitle}}
            </router-link>
            <i class="fa fa-caret-right"></i>
            {{coachingtitle}}
        </div>
            <coaching v-bind:coaching="coaching" 
                        v-bind:user="user" 
                        v-bind:boughtcoachings="boughtcoachings" 
                        v-bind:paypalinit="paypalisinitialized"
                        v-bind:purchaseloaded="purchaseloaded"
                        v-on:achateffectue="onAchatEffectue"/>


        <loader v-bind:loaderenabled="loaderenabled"/>
    </div>
</template>

<script>
import paypalinit from "../components/PaypalInit.vue";
import coaching from "../components/CoachingDetails.vue";
import loader from "../components/Loader.vue";
import tool from "../assets/js/tool.js";

export default {
    data: function(){
        return {
            paypalisinitialized:false,
            loaderenabled: true,
            coaching:null,
            currentcategory:null,
            mycoachings:null,
            purchaseloaded:false 
        }
    },    
    components: {
        coaching,
        paypalinit,
        loader
    },
    props: {
        user: Object
    },
    methods: {
        fetchCoaching : async function(){
            this.loaderenabled= true;
            this.coaching = await tool.getCoaching(this.$route.params.id);
            let cat = this.coaching.category;
            if(cat){
                this.currentcategory = await tool.getCategory(cat);
            }
            tool.settitle(this.pageTitle());
            tool.setmetadescription(this.coaching.seodesc);
            await tool.setthumbnail(this.coaching.seothumbnail);
            await tool.setcanonicalpagelink('/coaching/'+this.coaching.url);
            await tool.setProductRichData(this.coaching);
            this.loaderenabled= false;
        },
        displayallcoachings: function(){
            this.$router.push({name:'Home'})
        },
        displaymycoachings: function(){
            if(this.$route.name!='MyCoachings')
                this.$router.push({name:'MyCoachings'})
        },
        fetchMyCoachings: async function(){
            this.loaderenabled= true;
            this.mycoachings = await tool.getMyCoachings();
            this.purchaseloaded = true;
            this.loaderenabled= false;
        },
        onAchatEffectue: function(aboughtcaoching){
            if(!this.mycoachings){
                this.mycoachings = [];
            }
            this.mycoachings.push(aboughtcaoching);
            this.$router.push({name:"MyCoachings"});
        },
        reloadAll: async function(){
            this.fetchCoaching();
            this.fetchMyCoachings();
        },
        pageTitle: function(){
            let tit = 'Coaching Back To Relaxation';
            if (this.coaching){
                tit =' | ' + tit;
                if(this.coaching.pagetitle){
                    tit=this.coaching.pagetitle + tit;
                }else {
                    tit=this.coaching.title + tit;
                }
            }
            return tit;
        } 
    },
    computed: {
        boughtcoachings:function(){   
            return this.mycoachings;
        },
        categorytitle:function(){
            return this.currentcategory ? (this.currentcategory.breadcrumbtitle ? this.currentcategory.breadcrumbtitle : this.currentcategory.title):'';
        },
        coachingtitle: function(){
            return this.coaching? (this.coaching.breadcrumbtitle ? this.coaching.breadcrumbtitle : this.coaching.title) : '';
        }
    },
    created : function(){
        this.reloadAll();
    },
    watch: {
        // call again the method if the route changes
        '$route': 'reloadAll'
    },
}
</script>

<style scoped>

    .frame {
        padding: 12px 12px;
        box-shadow:0 4px 20px 0;
    }

    .content {
        padding: 10px;
        box-shadow:0 4px 20px 0;
    }

    .content:after {
        content: "";
        clear: both;
        display: table;
    }

    .breadcrumb {
        margin-top: 5px;
        /* margin-bottom: 10px; */
        padding: 3px;
        font-size: 16px;
        text-decoration: none;
        font-weight:600;
    }

    .breadcrumb a {
        text-decoration: none;
        padding-right:5px;
        padding-left: 5px;
    }

</style>