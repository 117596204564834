<template>
    <div v-if="loaderenabled" class="loaderline">
        <div class="loadercontainer">
            <div class="loader">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
            loaderenabled: Boolean
    }
}
</script>
<style scoped>
    .loader {
        border: 16px solid #EBF7E3;
        border-top: 16px solid #66B032; 
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .loaderline {
        width: 100%;
        /* padding-top: 30px; */
        position:absolute;
        top:50%;
    }
    .loadercontainer {
        width:120px;
        margin-right:auto;
        margin-left:auto;
    }
</style>