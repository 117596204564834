<template>
    <div v-bind:style="'display:'+buttondisplay">
        <roundedbutton :dark="true" :size="'big'" v-on:clicked="this.displaypopup">
            Acheter
        </roundedbutton>
        <div class="paypalbuttons b-clr1" :style="this.popupstyle">
            <span class="close txt-clr2" title="Close Modal" v-on:click="this.hidepopup">&times;</span>
            <div class="paypalcontainer">
                <div class="paypalcentered">
                    <div class="txt-clr1 b-clr5 paypaltitle">Acheter avec :</div>
                    <div class="b-clr1 paypal">
                        <div v-bind:id="paypalbuttonid"  ></div>
                    </div>
                </div>
            </div>
            <div v-if="ispaypalinit && true"></div>
            <div v-if="loaderenabled" class="loaderline">
                <div class="loadercontainer">
                    <div class="loader">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import tool from "../assets/js/tool.js";
import roundedbutton from "../microcomponents/roundedbutton.vue"

export default {
    data() {
        return {
            buttonenabled:false,
            ismounted:false,
            loaderenabled:false,
            popupstyle:"display:none"
        }
    },
    props:{
        coaching: {
            type:Object
        },
        user: {
            type: Object
        },
        paypalinit:{
            type:Boolean
        },
        iscoachingalreadybought: {
            type:Boolean
        },
        purchaseloaded:{
            type:Boolean
        },
        position:{
            type:Number
        }
    },
    emits: [
        "achateffectue"
    ],
    components:{
        roundedbutton
    },
    methods:{
        renderPaypal:function(){
            this.buttonenabled=true;
            let self = this;
            let divid = '#'+self.paypalbuttonid;
            if(!this.iscoachingalreadybought){
                window.paypal.Buttons({
                    style: {
                        layout: 'vertical',
                        color:  'white',
                        shape:  'rect',
                        label:  'paypal'
                    },
                    createOrder: function(data, actions) {
                        // Set up the transaction
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: self.coaching.price
                                }
                            }],
                            application_context:{
                                user_action:"PAY_NOW"
                            }
                        });
                    },
                    onApprove: function(data, actions) {
                        // This function captures the funds from the transaction.
                        return actions.order.capture().then(function(details) {
                            self.createPurchase(details);
                        });
                    }
                }).render(divid);
            }
        },
        createPurchase:function(payment){
            this.loaderenabled = true;
            if(payment){
                let self = this;
                let coachingname = this.coaching.title;
                let ukey = tool.getUserKey(this.user);
                let coachingId = this.coaching.RowKey;
                let url = tool.getUrl("/api/achats/"+ukey);
                let payer = payment.payer;

                let purchasedata = {
                    purchaseid : payment.id,
                    purchasetime: payment.create_time,
                    mail:payer.email_address,
                    prenom:payer.name.given_name,
                    nom:payer.name.surname,
                    coachingid:coachingId
                }
                if(payment.purchase_units && payment.purchase_units[0]){
                    let amount = payment.purchase_units[0].amount;
                    purchasedata.currency=amount.currency_code;
                    purchasedata.price=amount.value;
                }
                let body = JSON.stringify(purchasedata);
                fetch(url,{
                    method:'POST',
                    body: body
                }).then(function(){
                    alert("Merci pour votre achat de "
                        +coachingname
                        +" que vous pouvez retrouvez dans 'Mes coachings achetés'"
                    );
                    self.$emit("achateffectue", self.coaching);
                    self.loaderenabled=false;  
                }).catch(function(error){
                    self.loaderenabled=false;
                    alert("Une erreur a eu lieu : "+error.message)
                });
                    
            }else{
                this.loaderenabled=false;
            }
        },
        displaypopup: function(){
            window.scrollTo(0,0);
            this.popupstyle="display:block";
        },
        hidepopup: function(){
            this.popupstyle="display:none";
        },
    },

    computed: {
        paypalbuttonid:function(){
            return 'paypaldiv'+ this.coaching.RowKey+"_"+this.position;
        },
        buttondisplay:function(){
            if(!this.buttonenabled){
                return "none";
            }else {
                return "block";
            }
        },
        ispaypalinit:function(){
            if(this.paypalinit && !this.buttonenabled && this.ismounted && this.purchaseloaded){
                this.renderPaypal();
            }
            return this.paypalinit && this.purchaseloaded;
        }
    },
    updated:function(){
        if(this.paypalinit && !this.buttonenabled && this.ismounted){
             this.renderPaypal();
        }
    },
    created:function(){
        // if(this.paypalinit  && !this.buttonenabled){
        //    this.renderPaypal();
        // }
    },
    mounted:function(){
        this.ismounted=true;
        if(this.paypalinit  && !this.buttonenabled){
           this.renderPaypal();
        }
    }
}
</script>
<style scoped>
    .button {
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    .hasMargin {
        margin: 10px;
    }
    .paypalcontainer{
        min-width:200px;
        margin-right:auto;
        margin-left:auto;
        width:30%;
        /* opacity:1; */
        max-width:400px;
        padding-top: 200px;
    }

    .paypalcentered {
        margin:0px;
    }
    .paypaltitle{
        font-size: 36px;
        padding:10px;
        text-align:center;
    }
    .paypalbuttons {
        position: absolute; /* Stay in place */
        z-index: 10; /* Sit on top */
        left: 0px;
        top: 0px;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
        
        float:none;
    }

    .paypal {
        padding: 30px;
    }
    .close {
        /* Position it in the top right corner outside of the modal */
        position: absolute;
        right: 25px;
        top: 0;
        font-size: 35px;
        font-weight: bold;
        cursor:pointer;
    }
</style>