<template>
    <div class="dynamiccontentedit">
        <hr/>
        <div v-if="this.displayed">
            <div class="row">
                <div class="col-25">
                    Type : {{content.type}}
                </div>
                <div class="col-75">
                    Order : <input v-model="content.order"/>
               
                    <button class="button txt-clr0 b-clr3 hb-clr4 hasMargin right" v-on:click="this.delete" >X</button>               
                </div>    
            </div>

            <imageEdit :content="content" v-if="content.type == 'image'" />
            <htmlEdit :content="content" v-if="content.type == 'html'"/>
            <textEdit :content="content" v-if="content.type == 'text'"/>
            <pdfEdit :content="content" v-if="content.type == 'pdf'"/>
        </div>
    </div>
</template>

<script>
import imageEdit from './DynamicImageEdit.vue';
import htmlEdit from './DynamicHtmlEdit.vue';
import textEdit from './DynamicTextEdit.vue';
import pdfEdit from './DynamicPdfEdit.vue';

export default {
    props:{
        content: {
            type:Object
        }
    },
    components:{
        imageEdit,
        htmlEdit,
        textEdit,
        pdfEdit
    },
    methods: {
        delete : function(){
            this.content.deleted = true;
        }
    },
    computed:{
        displayed: function(){
            let disp = !( this.content.deleted === true);
            return disp;
        }
    }
}
</script>

<style>
 /* Floating column for labels: 25% width */
.dynamiccontentedit .col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

/* Floating column for inputs: 75% width */
.dynamiccontentedit .col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.dynamiccontentedit .row:after {
  content: "";
  display: table;
  clear: both;
}

.dynamiccontentedit .right {
    float: right;
}

.dynamiccontentedit .button {
    margin:0 10px 10px 10px;
    padding:10px;
    text-decoration: none;
    border-radius: 4px;
    border:none;
}

.dynamiccontentedit textarea {
    width:100%;
}
</style>