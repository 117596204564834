import { render, staticRenderFns } from "./PaypalView.vue?vue&type=template&id=f5d08e06&scoped=true"
import script from "./PaypalView.vue?vue&type=script&lang=js"
export * from "./PaypalView.vue?vue&type=script&lang=js"
import style0 from "./PaypalView.vue?vue&type=style&index=0&id=f5d08e06&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5d08e06",
  null
  
)

export default component.exports