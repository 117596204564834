<template>
    <div class="extract b-clr1">
        <router-link :to="{name: 'coaching', params: {id:coaching.url}}" class="packlink">
            <img :src="picture" class="picture"/>
        </router-link>
        <div class="txt-clr1 b-clr5 details" >
            <div v-if="coaching.title" class="coachingtitle" >{{coaching.title}}</div>
            <br/>
            <div class="shortdesc" v-html="coaching.shortdescription"></div>
            <roundedbtn :dark="true" :to="{name: 'coaching', params: {id:coaching.url}}" class="gobtn">En savoir plus</roundedbtn>
        </div>
    </div>
</template>
<script>
import tool from "../assets/js/tool.js";
import roundedbtn from "../microcomponents/roundedbutton.vue";

export default {
    props: {
        coaching: Object,
        hasEdit: Boolean,
        even: Boolean
    },
    computed:{
        picture: function(){
            return tool.getUrl('/api/image/'+this.coaching.smallpictureUrl);
        }
    },
    components:{
      roundedbtn
    }   
}


</script>
<style scoped>
.extract{
    padding:6px;
}
.packlink{
    width:100%;
}
.picture{
    width:100%;
    vertical-align: middle;
}
.coachingtitle{
    font-weight:700;
}
.details {
    padding-top:12px;
    padding-right:12px;
    padding-bottom:75px;
    padding-left:12px;
    position:relative;
}
.gobtn{
    position:absolute;
    right:20px;
    bottom: 20px;
}

@media screen and (max-width: 1200px) and (min-width: 769px){
}

@media screen and (max-width: 768px){
    .extract{
        padding:0px;
    }
}
</style>
