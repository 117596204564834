<template>
    <div class="videoeditcontainer b-clr2">
        <div v-if="opened">
            <div class="row">
                <div class="col-25">
                    <label for="fvidrowkey">Row Key</label>
                </div>
                <div class="col-75">
                    <input type="text" id="fvidrowkey" placeholder="Rowkey..." v-model="video.RowKey" disabled>
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <label for="fid">Id</label>
                </div>
                <div class="col-75">
                    <input type="text" id="fid" placeholder="Id..." v-model="video.id" disabled>
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <label for="fvidtitle">Titre</label>
                </div>
                <div class="col-75">
                    <input type="text" id="fvidtitle" placeholder="Titre..." v-model="video.title">
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <label for="fpos">Position</label>
                </div>
                <div class="col-75">
                    <input type="number" id="fpos" placeholder="Position..." v-model="video.position">
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <label for="ftabgroup">Tab Group</label>
                </div>
                <div class="col-75">
                    <input id="ftabgroup" placeholder="Tab group..." v-model="video.tabgroup">
                </div>
            </div>

            <div class="row">
                <div class="col-25">
                    <label for="ftablabel">Tab Label</label>
                </div>
                <div class="col-75">
                    <input id="ftablabel" placeholder="Tab label..." v-model="video.tablabel">
                </div>
            </div>

            <div class="row">
                <div class="col-25">
                    <label for="fpre">Pr&eacute;ambule</label>
                </div>
                <div class="col-75">
                    <textarea id="fpre" placeholder="Préambule..." style="height:100px" v-model="video.preambule"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <label for="fdescview">Pr&eacute;ambule : Apparence</label>
                </div>
                <div class="col-75">
                    <div id="fdescview" v-html="video.preambule"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-25">
                    <label for="fvidurl">Video Url</label>
                </div>
                <div class="col-75">
                    <!-- <input type="text" id="fvidurl" placeholder="Url de la video" v-model="video.videourl" > -->
                    <blobpicker type="video" placeholder="Url de la video" v-model="video.videourl" v-on:input="newVideo()"/>
                    <!-- {{videostream}} -->
                    <video width="320" height="240" controls v-bind:src="videostream" v-if="videostream">
                    </video>
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <label for="fvidpictureUrl">Image de la Video</label>
                </div>
                <div class="col-75">
                    <blobpicker type="images" id="fvidpictureUrl" placeholder="Image placée sur la video.." v-model="video.videoPictureUrl"/>
                    <img v-bind:src="vidpicture">
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <label for="fdesc">Description</label>
                </div>
                <div class="col-75">
                    <textarea id="fdesc" placeholder="Description..." style="height:100px" v-model="video.description"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-25">
                    <label for="fdescview">Description : Apparence</label>
                </div>
                <div class="col-75">
                    <div id="fdescview" v-html="video.description"></div>
                </div>
            </div>

            <div class="row">
                <input type="submit" value="Supprimer" v-on:click="ondelete();" class="txt-clr0 b-clr3 hb-clr4">  
                <input type="submit" value="Enregistrer" v-on:click="onsave()" class="txt-clr0 b-clr3 hb-clr4">
                <input type="submit" value="Enregistrer et fermer" v-on:click="onsave();close()" class="txt-clr0 b-clr3 hb-clr4">
                <input type="submit" value="Fermer" v-on:click="close()" class="txt-clr0 b-clr3 hb-clr4">
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-25">
                    <label for="ftitle">Titre</label>
                </div>
                <div class="col-75">
                    <input type="text" id="ftitle" placeholder="Titre..." v-model="video.title" disable>
                    <input type="submit" value="Ouvrir" v-on:click="open()" class="hasmargin txt-clr0 b-clr3 hb-clr4">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import tool from "../assets/js/tool.js";
import blobpicker from "./BlobPicker.vue";

export default {
        data: function(){
        return {
            isOpen:true,
            calculatedVideoUrl:""
        }
    },
    props:{
        video: {
            type:Object
        }
    },
    methods:{
        close:function(){
            this.isOpen = false;
        },
        open:function(){
            this.isOpen = true;
        },
        onsave:function(){
            let posturl = tool.getUrl('/api/videometa/'+this.video.PartitionKey);
            let body = JSON.stringify(this.video);
            fetch(posturl,{
                method : "POST",
                body: body
            });

            this.$emit('save', this.video);
        },
        ondelete:function(){
            let delurl = tool.getUrl('/api/videometa/'+this.video.PartitionKey);
            let body = JSON.stringify(this.video);
            fetch(delurl,{
                method : "DELETE",
                body: body
            });
            this.$emit('delete', this.video);
        },
        newVideo:function(){
            if (this.video && this.video.videourl){
                var url = tool.getUrl('/api/video/'+this.video.videourl);
                fetch(url).then(
                    response => response.json()
                ).then(
                    data => {
                        if(data){
                            this.calculatedVideoUrl = data.url;
                        }
                    }
                );
            }
        }
    },
    emits: [
        "save",
        "delete"
    ],
    computed:{
        mainpicture: function(){
            if (this.video && this.video.mainPictureUrl){
                return tool.getUrl('/api/image/'+this.video.mainPictureUrl);
            } else return '';
        },
        vidpicture: function(){
            if (this.video && this.video.videoPictureUrl)
                return tool.getUrl('/api/image/'+this.video.videoPictureUrl);
            else return '';
        },
        opened: function(){
            return this.isOpen;
        },
        videostream: function(){
            return this.calculatedVideoUrl;
        }
    },
    created: function(){
        this.newVideo();
    },
    components:{
        blobpicker
    }
}
</script>

<style scoped>
/* Style inputs, select elements and textareas */
input[type=text], input[type=number], select, textarea{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

/* Style the label to display next to the inputs */
label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

/* Style the submit button */
input[type=submit] {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  float: right;
}

.hasmargin {
    margin:10px;
}

/* Style the container */
.videoeditcontainer {
  /* border-radius: 5px; */
  opacity: 0.9;
  padding: 20px;
}

/* Floating column for labels: 25% width */
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

/* Floating column for inputs: 75% width */
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
} 
</style>