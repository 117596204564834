<template>
    <div class="horizontalmenu">
        <horizontalmenuitem v-for="anitem in menus" :menuitem="anitem" v-bind:key="anitem.label" :isOpaque="isOpaque"/>
    </div>
</template>

<script>
import horizontalmenuitem from './horizontalmenuitem.vue'
export default {
    props : {
        menus:Array,
        isOpaque:Boolean
    },
    components:{
        horizontalmenuitem
    }

}
</script>

<style scoped>
.horizontalmenu {
    display:inline-block
}

@media screen and (max-width: 1200px){
    .horizontalmenu {
        display:none
    }
}

</style>