<template>
  <div v-if="this.value" class="modal">
    <span class="close txt-clr2" title="Close Modal" v-on:click="$emit('close',$event.target.value);">&times;</span>
    <div class="modal-content animate b-clr1">
      <div class="container">
        <div class="loginmessage">Veuillez vous connecter avec votre identifiant habituel
        </div>
        <div class="btnwrap">
          <a :href="'/.auth/login/aad?post_login_redirect_uri='+postloginurl" class="windows btn"><i class="fa fa-windows fa-fw"></i> Se connecter avec Microsoft</a>
        </div>
        <div class="btnwrap">      
          <a :href="'/.auth/login/google?post_login_redirect_uri='+postloginurl" class="google btn"><i class="fa fa-google fa-fw"></i> Se connecter avec Google</a>
        </div>
        <div class="btnwrap" style="display:none">
          <a :href="'/.auth/login/twitter?post_login_redirect_uri='+postloginurl" class="twitter btn"><i class="fa fa-twitter fa-fw"></i> Se connecter avec Twitter</a>
        </div>
        <div class="btnwrap">
          <a :href="'/.auth/login/facebook?post_login_redirect_uri='+postloginurl" class="fb btn"> <i class="fa fa-facebook fa-fw"></i> Se connecter avec Facebook</a>
        </div>
        <div class="explaination"><b>Premi&egrave;re connexion : </b> vous devrez acceptez les conditions d'utilisation dans 
          les pages suivantes dans le but de pouvoir retrouver vos achats lors d'une prochaine visite. Nous ne stockons que 
          votre identifant et le r&eacute;seau social utilis&eacute; pour chaque achats<br/>
          <b>En vous connectant, vous acceptez l'utilisation de cookies d'authentification par ce site et par le fournisseur d'identité choisi</b>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
    export default {
        data: function(){
            return {
                
            }
        },
        props:{
          value : Boolean
        },
        methods: {

        },
        computed : {
          postloginurl : function(){
            let url = window.location.origin+this.$route.path;
            return url;
          }
        },
        emits: [
          "close"
        ]

    }
</script>

<style scoped>
.container {
  max-width:670px;
  padding-right : 10px;
  /* width:100%; */
  /* margin-left: auto;
  margin-right: auto; */
  
}

.loginmessage {
  font-size: 25px;
  margin: 25px;
  text-align: center;
}

.explaination {
  font-size: 12px;
  margin: 10px;
}
/* style  link buttons */
.btnwrap{
  padding:5px;
  width:100%;
}
.btn {
   width: 100%;
  /* max-width: 300px; */
  padding-top: 12px;
  padding-bottom: 12px;
  border: none;
  border-radius: 4px;
  /* margin: 5px 5px; */
  /* opacity: 0.85; */
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  text-align: center;
}

/* add appropriate colors to fb, twitter and google buttons */
.fb {
  background-color: #3B5998;
  color: rgb(230, 230, 230);
}

.fb:hover{
  background-color: #2f4679;
  color: white;
}


.twitter {
  background-color: #55ACEE;
  color: rgb(230, 230, 230);
}

.twitter:hover {
  background-color: #5097ce;
  color: white;
}

.google {
  background-color: #dd4b39;
  color: rgb(230, 230, 230);
}

.google:hover {
  background-color: #b4311f;
  color: white; 
}

.windows {
  background-color: #00C3F4;
  color: rgb(230, 230, 230);  
}

.windows:hover {
  background-color: #2ab5d8;
  color: white;    
}

.modal {
  position: absolute; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0px;
  top: 0px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
  padding-top: 200px;
  float:none;
}

  /* Modal Content/Box */
.modal-content {
  margin: 5px auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width:672px;
  float:none;

}

  /* The Close Button */
.close {
  /* Position it in the top right corner outside of the modal */
  position: absolute;
  right: 25px;
  top: 0;
  font-size: 35px;
  font-weight: bold;
  cursor:pointer;
}

  /* Close button on hover */
.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}

  /* Add Zoom Animation */
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)}
  to {-webkit-transform: scale(1)}
}

@keyframes animatezoom {
  from {transform: scale(0)}
  to {transform: scale(1)}
}
</style>