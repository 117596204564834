<template>
    <div>        
        <coachingview v-if="coaching" v-bind:coaching="coaching" v-bind:user="user" v-on:close="close">

        </coachingview>
        <loader v-bind:loaderenabled="loaderenabled"/>
    </div>    
</template>
<script>

import loader from '../components/Loader.vue';
import coachingview from "../components/MonCoachingView.vue";
import tool from "../assets/js/tool.js";

export default {
    data: function(){
        return {
              coaching: null,
              loaderenabled: false          
        }
    },
    title: 'Mon coaching | Coaching Back To Relaxation',
    components: {
        loader,
        coachingview
    },
    props: {
        user: Object
    },
    methods:{
        fetchCoaching: async function(){
            if (this.$route.params.id){
                try {
                    this.loaderenabled = true;
                    let coachingkey = this.$route.params.id;
                    this.coaching = await tool.getCoachingForUser(coachingkey);
                    this.loaderenabled = false;
                } catch {
                    this.loaderenabled = false;
                }
            }
        },
        close: function(){
            this.$router.push({name: 'MyCoachings'});
        }
    },
    created: async function(){
        await this.fetchCoaching();
    },
    watch: {
        // call again the method if the route changes
        '$route': 'fetchCoaching'
    },
}
</script>