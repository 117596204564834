<template>
  <div>
    <div>
        Toolbar 
        <button v-on:click="addImage" class="txt-clr0 b-clr3 hb-clr4 displaybutton">Add Image</button>
        <button v-on:click="addHtml" class="txt-clr0 b-clr3 hb-clr4 displaybutton">Add Html</button>
        <button v-on:click="addText" class="txt-clr0 b-clr3 hb-clr4 displaybutton">Add Text</button>
        <button v-on:click="addPdf" class="txt-clr0 b-clr3 hb-clr4 displaybutton">Add Pdf</button>
    </div>
    <div v-for="(dynContent, dynIndex) in sortedDynamicContent" :key="dynIndex">
        <dynamicContent :content="dynContent"/>
    </div>
  </div>
</template>

<script>
import dynamicContent from './DynamicContentEdit.vue';
import tool from '../assets/js/tool.js';

export default {
    data:function(){
        return {
            
        }
    },
    props: {
        dynamicContentList : {
            type : Array
        }
    },
    components:{
        dynamicContent
    },
    methods:{
        addImage : function(){
            var newImage = tool.newDynamicContent("image",this.newOrder);
            this.dynamicContentList.push(newImage);
        },
        addHtml : function(){
            var newHtml = tool.newDynamicContent("html", this.newOrder);
            this.dynamicContentList.push(newHtml);
        },
        addText : function(){
            var newText = tool.newDynamicContent("text", this.newOrder);
            this.dynamicContentList.push(newText);
        },
        addPdf : function(){
            var newPdf = tool.newDynamicContent("pdf", this.newOrder);
            this.dynamicContentList.push(newPdf);
        }
    },
    computed: {
        newOrder: function(){
            let max = 0;
            for(const contentitem of this.dynamicContentList){
                let val = parseInt(contentitem.order, 10);
                if(!isNaN(val) && max < val){
                    max = val;
                }
            }
            return max+1;
        },
        sortedDynamicContent: function(){
            if (this.dynamicContentList){
                let sortedArray = this.dynamicContentList.slice().sort(tool.dynamicContentSort);
                return sortedArray;
            }else {
                return [];

            }
        }
    }
}
</script>

<style scoped>
.displaybutton {
    margin:10px;
    padding:10px;
    text-decoration: none;
    border-radius: 4px;
    border:none;
}
</style>