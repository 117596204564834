import { render, staticRenderFns } from "./DefFooter.vue?vue&type=template&id=2af1f44b&scoped=true"
var script = {}
import style0 from "./DefFooter.vue?vue&type=style&index=0&id=2af1f44b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2af1f44b",
  null
  
)

export default component.exports