<template>
    <div v-bind:id="ancre">
        <div class="coaching">
            <div class="section">
                <div v-if="coaching.ismini" class="sgauchemini">
                     <img :src="smallpicture"/>
                </div>
                <div v-else class="sgauche" v-bind:style="'background-image: url('+smallpicture+')'">
                    <textonimage :coaching="coaching"/>
                </div>
                <div class="scentre">
                    <div class="title">{{coaching.title}}</div>
                    <br/>
                    <div class="shortdesc" v-html="coaching.shortdescription"></div>
                </div>
                <div class="sdroit">
                    <div v-if="coaching.pricebeforediscount > 0" class="pricebefore">
                        {{coaching.pricebeforediscount}}€
                    </div>
                    <div class="price txt-clr4" v-if="coaching.price > 0">
                        {{coaching.price}}€
                    </div>
                    <div v-else-if="coaching.alternatepriceblock" class="txt-clr4 price">
                        <div v-html="coaching.alternatepriceblock"></div>
                    </div>
                    <div v-else class="txt-clr4 price">
                        Sur devis
                    </div>
                    <button class="button hasMargin txt-clr0 b-clr3 hb-clr4 longdescopen" v-if="!longdescopen" v-on:click="longdescopen=true">En savoir plus</button> 
                    <div style="height:5px"/>
                    <router-link v-if="editbuttondisplayed" class="button hasMargin txt-clr0 b-clr3 hb-clr4 longdescopen"  :to="{name:'EditCoaching', params: {id:coaching.RowKey}}">Editer</router-link>
                </div>
            </div>
        </div>
        <div v-if="longdescopen" class="longdesccontainer b-clr1">
            <button class="button txt-clr0 b-clr3 hb-clr4 hasMargin longdescbutton" v-on:click="longdescopen=false" >X</button>               
            <div class="longdesc" v-html="coaching.longdescription">
            
            </div>
        </div>
    </div>
</template>

<script>
import tool from "../assets/js/tool.js";
import textonimage from "./TextOnImage.vue";

export default {
    data: function(){
        return {
            longdescopen:false
        }
    },
    props:{
        coaching: {
            type:Object
        },
        user: {
            type: Object
        }
    },
    components:{
        textonimage
    },
    computed:{
        smallpicture: function(){
            return tool.getUrl('/api/image/'+this.coaching.smallpictureUrl);
        },
        editbuttondisplayed:function(){
            return this.user && this.user.isAdmin;
        },
        ancre:function(){
            return this.coaching.url;
        }   
    }
}
</script>

<style scoped>
    .notconnected{
        text-align: center;
        padding-top:10px;
        padding-right:10px;
        padding-left:10px;
    }
    .pricebefore{
        color: orangered;
        font-style: oblique;
        text-decoration-line: line-through;
        font-size: 30px;
        margin: 10px;
    }
    .price {
        font-size: 38px;
        margin: 10px;
    }
    .coaching {
        width:100%;
        /* height: 255px; */
        display: flex;
        flex: 1;

    }

    .coaching:after {
        content: "";
        clear: both;
        display: table;
    }

    .section {
        /* height:255px;  */
        display: flex;
        flex:1; 
        /* width: 100%;       */
    }

    .sgauche {
        min-width:450px;
        order: 1;
        min-height:255px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        overflow:hidden;
    }
    .sgauchemini {
        display: table-cell;
        width: 35%;
    }

    .sgauchemini img {
        width:100%;
    }

    .scentre {
        flex:1;
        order:2;
        padding-right:20px;
    }

    .sdroit{
        max-width:400px;
        order:3;
        /* float:right; */
    }


    .smallimage {
        /* float:left; */
        width:100%;
        margin-right:10px;
    }
    .title{
        font-size: 23px;
        padding:10px;
        
    }
    .shortdesc{
        font-size: 18px;
        font-style: italic;
        padding-left:10px;
    }

    .button {
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 400;
        font-size: 13px;
        text-decoration: none;
        /* float: right; */
    }
    .hasMargin {
        margin: 10px 0px;
    }
    .longdescopen {
        width:100%;
    }
    .longdesc {
        margin-top:15px;
        margin-bottom:10px;
        padding: 10px;
    }
    .longdesccontainer {
        padding: 10px;
        float:none;
        position:relative;
        margin-top:10px;
    }
    .longdescbutton{
        position:absolute;
        top:0px;
        right:0px;
        padding:8px;
    }

    .longdesc {
        margin-top:20px;
    }

    .paypalinfo {
        margin:10px;
        padding:20px;
        font-size:22px;
        /* box-shadow:0 4px 20px 0; */
    }

    @media screen and (max-width: 1200px){
        .coaching,
        .section,
        .sgauche,
        .scentre,
        .sdroit
        {
            width:auto;
            float:none;
            display:block;
            height:auto;
        }

        .sgauche
        {
            min-width:auto;
            width:auto;
            float:none;
            display:block;
            height:255px;
            background-position: left;
            overflow:hidden;
        }

    }
</style>