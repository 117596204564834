<template>
    <select v-bind:name='name' v-bind:value="currentVal" v-on:change="changeval($event.target.value)">
        <option v-for='cat in categorylist' v-bind:value='cat.RowKey' v-bind:key='cat.RowKey' v-bind:selected="isSelected(cat)">{{cat.title}}</option>
    </select>
</template>
<script>
import tool from  "../assets/js/tool.js";

export default {
    data: function(){
        return {
            categorylist:[]
        }
    },
    props:{
        name: String,
        value: String
    },
    methods:{
        fetchcategories: async function(){
            this.categorylist = await tool.getCategories(true);
        },
        changeval: function(val){
            this.$emit('input', val);
        },
        isSelected: function(acat){
            var isSel = this.value==acat.RowKey;
            return isSel;
        }
    },
    created: function(){
        this.fetchcategories();
    },
    computed: {
        currentVal: function(){
            return this.value;
        }
    },
    emits: [
        "input"
    ]
}
</script>