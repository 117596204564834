<template>
    <div v-if="coaching">
        <div class="coachingmini">
            <div class="sectionmini b-clr5 txt-clr1">
                <!-- <div class="sgauchemini"> -->
                <!-- <div class="sgauchemini" :style="'background-image: url('+smallpicture+') ;'"> -->
                    <img :src="smallpicture" class="sgauchemini" :alt="coaching.smallpicturealt"/>
                <!-- </div> -->
                <div class="scentremini ">
                    <div class="title">{{coaching.title}}</div>
                    <br/>
                    <div class="shortdesc" v-html="coaching.shortdescription"></div>
                </div>
                <div class="sdroitmini b-clr5 txt-clr1">
                    <div v-if="coaching.pricebeforediscount > 0" class="pricebefore txt-clr2">
                        {{coaching.pricebeforediscount}}€
                    </div>
                    <div class="price txt-clr1" v-if="coaching.price > 0">
                        {{coaching.price}}€
                    </div>
                    <div v-else-if="coaching.alternatepriceblock" class="txt-clr4 price">
                        <div v-html="coaching.alternatepriceblock"></div>
                    </div>
                    <div v-else class="txt-clr4 price">
                        Sur devis
                    </div>

                    <paypal v-if="user && !iscoachingalreadybought && coaching.hasPaypal" 
                            v-bind:coaching="coaching" 
                            v-bind:user="user"
                            v-bind:iscoachingalreadybought="iscoachingalreadybought" 
                            v-bind:paypalinit="paypalinit"
                            v-bind:purchaseloaded="purchaseloaded"
                            v-bind:position="1"
                            v-on:achateffectue="onAchatEffectue"></paypal>
                    <roundedbutton v-else-if="!user && coaching.hasPaypal" :dark="true" v-on:clicked="onConnectButtonClick()">Acheter</roundedbutton>  

                    <div v-else-if="coaching.hasDoctolib">
                        <resaenlignebutton />
                    </div>
                </div>
            </div>
            <div class="scentreminibottom b-clr5 txt-clr1">
                <div class="title">{{coaching.title}}</div>
                <br/>
                <div class="shortdesc" v-html="coaching.shortdescription"></div>
            </div>
        </div>

        <longdesc :longdescription="coaching.longdescription">
            
        </longdesc>

        <paypal v-if="user && !iscoachingalreadybought && coaching.hasPaypal" 
                            v-bind:coaching="coaching" 
                            v-bind:user="user"
                            v-bind:iscoachingalreadybought="iscoachingalreadybought" 
                            v-bind:paypalinit="paypalinit"
                            v-bind:purchaseloaded="purchaseloaded"
                            v-on:achateffectue="onAchatEffectue"
                            v-bind:position="2"></paypal>
        <roundedbutton v-else-if="!user && coaching.hasPaypal" :dark="true" v-on:clicked="onConnectButtonClick()">Acheter</roundedbutton>  


        <login v-bind:value="PopupShown" v-on:close="PopupShown=false"></login>
    </div>
</template>

<script>
import tool from "../assets/js/tool.js";
import login from "./Login.vue";
import paypal from "./PaypalView.vue";
import resaenlignebutton from "./ResaEnLigneBouton.vue";
import roundedbutton from "../microcomponents/roundedbutton.vue"
import longdesc from "../microcomponents/longdesc.vue"

export default {
    data: function(){
        return {
            PopupShown:false
        }
    },
    props:{
        coaching: {
            type:Object
        },
        user: {
            type: Object
        },
        boughtcoachings: {
            type:Array
        },
        paypalinit:{
            type:Boolean
        },
        purchaseloaded:{
            type:Boolean
        }
    },
    components:{
        login,
        paypal,
        resaenlignebutton,
        roundedbutton,
        longdesc
    },
    computed:{
        smallpicture: function(){
            if (this.coaching){
                return tool.getUrl('/api/image/'+this.coaching.smallpictureUrl);
            }else {
                return '';
            }
        },
        iscoachingalreadybought:function(){
            return this.testiscoachingalreadybought();
        }        
    },
    emits: [
        "achateffectue"
    ],
    methods:{
        testiscoachingalreadybought:function(){
            if (!this.purchaseloaded ){
                return true;
            }
            if(this.coaching && this.purchaseloaded){
                let result = this.boughtcoachings.filter(x => x.RowKey==this.coaching.RowKey);
                return result && result.length>0;
            }else {
                return false;
            }
        },
        onAchatEffectue:function(aboughtcaoching){
            this.$emit("achateffectue",aboughtcaoching);
        },
        onConnectButtonClick:function(){
            this.PopupShown=true;
            window.scrollTo(0,0);
        }
    },
    mounted:function(){
        let hash = window.location.hash;
        if(hash){
            hash = hash.substring(1);
            if( this.$el.id == hash){
                let top = this.$el.offsetTop;
                window.scrollTo(0, top);
            }
        }
    }
}
</script>

<style scoped>
    .notconnected{
        text-align: center;
        padding-top:10px;
        padding-right:10px;
        padding-left:10px;
    }
    .pricebefore{
        color: orangered;
        font-style: oblique;
        text-decoration-line: line-through;
        font-size: 30px;
        margin: 10px;
    }
    .price {
        font-size: 38px;
        margin: 10px;
    }
    .coaching {
        width:100%;
        max-width:1600px;
        height: 300px;
        display: flex;
        flex: 1;

    }

    .coachingmini {
        width:100%;
        max-width:1600px;
        height: 300px;
        width:100%;
        display:table;
    }

    .coaching:after {
        content: "";
        clear: both;
        display: table;
    }

    .sgauchemini {
        display: inline-block;
        width: 35%;
        height:auto; 
    }

    .scentremini {
        display: inline-block;
        width: 40%;
        vertical-align: top;
    }

    .scentreminibottom {
        display:none;
    }

    .sdroitmini {
        display: inline-block;
        width:25%;
        vertical-align: top;
    }


    .smallimage {
        /* float:left; */
        width:100%;
        margin-right:10px;
    }
    .title{
        font-size: 23px;
        padding:10px;
        
    }
    .shortdesc{
        font-size: 18px;
        font-style: italic;
        padding-left:10px;
    }

    .button {
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        
        /* float: right; */
    }
    .hasMargin {
        margin: 10px 0px;
    }

    .longdesc {
        margin-top:10px;
        margin-bottom:10px;
        padding: 10px;
        box-shadow:none;
    }
 
    .paypalinfo {
        margin:10px;
        padding:20px;
        font-size:22px;
        /* box-shadow:0 4px 20px 0; */
    }


    @media screen and (max-width: 1200px){
        .coaching,
        .section,
        .sgauche,
        .scentre,
        .sdroit
        {
            width:auto;
            float:none;
            display:block;
            height:auto;
        }

        .sgauche
        {
            min-width:auto;
            /* max-width:auto; */
            width:auto;
            float:none;
            display:block;
            height:255px;
            background-position: left;
            overflow:hidden;
        }

    }

    @media screen and (max-width: 1200px) and (min-width: 769px){
        .sectionmini{
            display: flex;
        }
        .sgauchemini {
            display: inline-block;
            width: 50%;
            padding-right:10px
        }

        .scentremini {
            display: none;
        }
        .sdroitmini {
            display: inline-block;
            width: 50%;
            padding-left:10px;
            padding-bottom: 5px;
        }
        .scentreminibottom {
            display: table-row;
        }
    }
    @media screen and (max-width: 768px){
        .sgauchemini {
            display: block;
            width: 100%;
            
            
        }

        .scentremini {
            display: block;
            width: 100%;
        }

        .sdroitmini {
            display: block;
            width: 100%;
            padding-bottom: 5px;
        }

        .scentreminibottom {
            display: none;
        }
    }
</style>