<template>
   <div class="container b-clr1-trans" v-if="category!=null">
        <div class="row">
            <div class="col-25">
                <label for="frowkey">Row Key</label>
            </div>
            <div class="col-75">
                <input type="text" id="frowkey" placeholder="Nom sans espace, ni majuscule, ni caractère spéciaux et surtout unique" v-model="category.RowKey">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="ftitle">Titre affiché</label>
            </div>
            <div class="col-75">
                <input type="text" id="ftitle" placeholder="Titre..." v-model="category.title">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fdarktitle">Titre sombre</label>
            </div>
            <div class="col-75">
                <input type="checkbox" id="fdarktitle"  v-model="category.isdarktitle">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="ftitlepage">Titre de page</label>
            </div>
            <div class="col-75">
                <input type="text" id="ftitlepage" placeholder="Titre..." v-model="category.pagetitle">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="ftitlebreadcrumb">Titre de fil d'arianne</label>
            </div>
            <div class="col-75">
                <input type="text" id="ftitlebreadcrumb" placeholder="Titre de fil d'arianne..." v-model="category.breadcrumbtitle">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="forder">Ordre de tri</label>
            </div>
            <div class="col-75">
                <input type="text" id="forder" placeholder="0" v-model="category.sortOrder">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fshortdesc">Description courte</label>
            </div>
            <div class="col-75">
                <textarea id="fshortdesc" placeholder="Description courte..." style="height:100px" v-model="category.shortdescription"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fshortdescview">Description courte : Apparence</label>
            </div>
            <div class="col-75">
                <div id="fshortdescview">{{category.shortdescription}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fseodesc">Description pour Google</label>
            </div>
            <div class="col-75">
                <textarea type="text" id="fseodesc" placeholder="Description..." style="height:100px" v-model="category.seodesc"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fseothumbnail">Vignette pour Google</label>
            </div>
            <div class="col-75">
                <blobpicker id="seothumbnail" placeholder="Vignette..." v-model="category.seothumbnail" type="images"/>
                <img v-bind:src="seothumbnail">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="flongdesc">Description Longue</label>
            </div>
            <div class="col-75">
                <textarea id="flongdesc" placeholder="Longue description..." style="height:200px" v-model="category.longdescription"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="flongdescview">Description Longue : Apparence</label>
            </div>
            <div class="col-75">
                <div id="flongdescview">{{category.longdescription}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fdef">Définition</label>
            </div>
            <div class="col-75">
                <textarea id="fdef" placeholder="Definition..." style="height:200px" v-model="category.definition"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="flongdescview">Definition : Apparence</label>
            </div>
            <div class="col-75">
                <div id="flongdescview">{{category.definition}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fpictureUrl">Image</label>
            </div>
            <div class="col-75">
                <blobpicker id="fpictureUrl" placeholder="Image..." v-model="category.pictureUrl" type="images"/>
                <img v-bind:src="picture">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fpublished">Publi&eacute;</label>
            </div>
            <div class="col-75">
                <input type="checkbox" id="fpublished" v-model="category.published">
            </div>
        </div>
        <div class="row">
            <input type="submit" value="Enregistrer" v-on:click="$emit('save', $event.target.value,false)" class="txt-clr0 b-clr3 hb-clr4">
            <input type="submit" value="Enregistrer et fermer" v-on:click="$emit('save', $event.target.value,true)" class="txt-clr0 b-clr3 hb-clr4">
            <input type="submit" value="Fermer" v-on:click="$emit('close', $event.target.value)" class="txt-clr0 b-clr3 hb-clr4">
        </div>
    </div> 
</template>

<script>
import tool from "../assets/js/tool.js";
import blobpicker from "./BlobPicker.vue";

export default {
    emits: [
        "save",
        "close"
    ],
    props:{
        category: {
            type:Object
        }
    },
    computed:{
        picture: function(){
            return tool.getUrl('/api/image/'+this.category.pictureUrl);
        },
        seothumbnail: function(){
            return tool.getUrl('/api/image/'+this.category.seothumbnail);
        },
    },
    components:{
        blobpicker
    }
}
</script>

<style scoped>
/* Style inputs, select elements and textareas */
input[type=text], input[type=number], select, textarea{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.hasmargin {
    margin:10px;
}

/* Style the label to display next to the inputs */
label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

/* Style the submit button */
input[type=submit] {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  float: right;
}

/* Style the container */
.container {
  /* border-radius: 5px; */
  /* opacity: 0.9; */
  padding: 20px;
}


/* Floating column for labels: 25% width */
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

/* Floating column for inputs: 75% width */
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
}
</style>
