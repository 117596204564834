<template>
  <div>
    <div v-for="dynContent in sortedDynamicContent" :key="dynContent.order">
        <dynamicContent :content="dynContent"/>
    </div>
  </div>
</template>

<script>
import dynamicContent from './DynamicContentView.vue';
import tool from '../assets/js/tool.js';

export default {
    data:function(){
        return {
            
        }
    },
    props: {
        dynamicContentList : {
            type : Array
        }
    },
    components:{
        dynamicContent
    },
    computed:{
        sortedDynamicContent: function(){
            let sortedArray = this.dynamicContentList.slice().sort(tool.dynamicContentSort);
            return sortedArray;
        }
    }
}
</script>

<style>

</style>