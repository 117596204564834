<template>
    <div class="b-clr1" v-if="isUserAdmin">
        <div style="clear:both"> 
            <div v-if="categorylist && categorylist.length>0">
                <div v-for="cat in categorylist" v-bind:key="cat.rowKey" class="row b-clr3">
                    <div class="content b-clr0">
                        <category v-bind:category="cat" 
                                    v-bind:hasEdit="true" 
                                    />
                    </div>
                </div>
            </div>
        </div>

        <Loader v-bind:loaderenabled="loaderenabled"/>
    </div>
</template>
<script>
import tool from "../assets/js/tool.js";
import category from "../components/CategoryInList.vue";
import Loader from '../components/Loader.vue';

export default {
    data: function(){
        return {
               categorylist:[],
               loaderenabled: false,         
        }
    },
    title : 'List Categroy for Edition | Coaching Back To Relaxation',
    components: { 
        Loader,
        category
    },
    props: {
        user: Object
    },
    methods:{
        fetchcategories: async function(){
            let self = this; 
            self.loaderenabled=true;
            self.categorylist = await tool.getCategoriesForAdmin();
            self.loaderenabled=false; 
        },
        routeUpdated: async function(){
            await this.fetchCategories();
            await tool.noseoinfo();
        }
    },
    created: async function(){
        await this.fetchcategories();
        await tool.noseoinfo();
    },
    computed: {
        isUserAdmin: function(){
            return tool.isUserAdmin(this.user)
        }
    },
    watch: {
        // call again the method if the route changes
        '$route': 'routeUpdated'
    },
}
</script>