export default {
    getUrl: function(originUrl){
        var root = process.env.VUE_APP_API_ROOT;
        if (root){
            return root+originUrl;
        }else {
            return originUrl;
        }
    },
    isUserAdmin: function(principal){
        if(principal){
            principal.isAdmin = principal.userRoles.includes("administrateur");
            return principal.isAdmin;
        }
        return false;
    },
    getUserDetails:function(user){
        if(user){
            let details = user.userDetails;
            let index = details.indexOf("#");
            if(index > 0){
                details = details.substring(index+1).trim();
            }
            return details;
        }
        return null;
    },

    getUserKey:function(user){
        if(user){
            if(!user.partKey){
                let details = this.getUserDetails(user);
                let userId = user.identityProvider+"_"+details;
                let key = btoa(userId);
                user.partKey = key;
            }
            return user.partKey;
        }
        return null;
    },
    getUser: async function(){
        const response = await fetch("/.auth/me");
        if (response){
            try {
            const payload = await response.json();
            const { clientPrincipal } = payload;
            return clientPrincipal;
            }catch{
                return null;
            }
        }else {
            return null;
        }
    
    },
    getCoachings: async function(category){
        var coachingapi = this.getUrl('/api/coaching');
        if(category){
            coachingapi+="?category="+category;
        }
        const response = await fetch(coachingapi);
        const coachings = await response.json();
        return coachings;
    },
    getCoachingsForAdmin: async function(category){
        var coachingapi = this.getUrl('/api/adm/coaching');
        if(category){
            coachingapi+="?category="+category;
        }
        const response = await fetch(coachingapi);
        const coachings = await response.json();
        return coachings;
    },
    getCoaching: async function(id){
        var coachingurl = null;
        if (isNaN(id)){
            coachingurl = '/api/coachingbyname/'+id;
        }else {
            coachingurl = '/api/coaching/'+id;
        }
        const coachingapi = this.getUrl(coachingurl);
        const response = await fetch(coachingapi);
        const coaching = await response.json();
        if(!coaching.dynamicContent){
            coaching.dynamicContent = [];
        }
        return coaching;
    },
    getCoachingForAdmin: async function(id){
        var coachingurl = '/api/adm/coaching/'+id;
        const coachingapi = this.getUrl(coachingurl);
        const response = await fetch(coachingapi);
        const coaching = await response.json();
        if(!coaching.dynamicContent){
            coaching.dynamicContent = [];
        }
        return coaching;
    },
    getCoachingForUser: async function(id){
        var coachingurl = '/api/usr/coaching/'+id;
        const coachingapi = this.getUrl(coachingurl);
        const response = await fetch(coachingapi);
        const coaching = await response.json();
        if(!coaching.dynamicContent){
            coaching.dynamicContent = [];
        }
        return coaching;
    },
    saveCoachingForAdmin: async function(coaching){
        const coachingapi = this.getUrl('/api/adm/coaching/'+coaching.RowKey);
        let body = JSON.stringify(coaching);
        await fetch(coachingapi, 
                {
                    method : "POST",
                    body: body
                }
        );
    },
    getCategories: async function(published){
        var categoryapi = this.getUrl('/api/categorie/');
        if (published){
            categoryapi+="?isPublished=true"
        }
        const response = await fetch(categoryapi);
        let categorylist = await response.json();
        categorylist = categorylist.sort(function(a, b) {
            return parseInt(a.sortOrder) - parseInt(b.sortOrder);
        });
        return categorylist;

    },
    getCategoriesForAdmin: async function(){
        var categoryapi = this.getUrl('/api/adm/categorie/');
        const response = await fetch(categoryapi);
        let categorylist = await response.json();
        categorylist = categorylist.sort(function(a, b) {
            return parseInt(a.sortOrder) - parseInt(b.sortOrder);
        });
        return categorylist;
    },
    getCategory: async function(name){
        const categoryapi = this.getUrl('/api/categorie/'+name);
        const response = await fetch(categoryapi);
        if (response.status<=400){
            const category = await response.json();
            return category;
        }
    },
    getCategoryForAdmin: async function(name){
        const categoryapi = this.getUrl('/api/adm/categorie/'+name);
        const response = await fetch(categoryapi);
        const category = await response.json();
        return category;
    },
    saveCategoryForAdmin: async function(category){
        const categoryapi = this.getUrl('/api/adm/categorie/'+category.RowKey);
        let body = JSON.stringify(category);
        await fetch(categoryapi, 
                {
                    method : "POST",
                    body: body
                }
        );
    },
    getMyCoachings: async function(){
        const user = await this.getUser();
        let mycoachings=[];
        if (user){
            const key = this.getUserKey(user);
            const purchaseapi = this.getUrl('/api/achats/'+key);
            const response = await fetch(purchaseapi);
            const purchases = await response.json();
            
            let aPurchase;
            for (aPurchase of purchases){
                let coaching = await this.getCoachingForUser(aPurchase.coachingid);
                mycoachings.push(coaching);
            }
        }
        return mycoachings;
    },
    newDynamicContent: function(type, order){
        let content = {
            type: type,
            order: order
        }
        content.hasBackground = true;
        
        switch (type){
            case "image":
                content.source = '';
                content.hasTopBackground = true;
                break;
            case "html":
                content.source = '';
                break;
            case "text":
                content.source = '';
                content.isDark = true;
                content.isCentered = true;
                break;
            case "pdf":
                content.source = '';
                content.image = '';
                content.text = '';
                break;
        }
        return content;
    },
    getBlobList: async function(container, startswith){
        const blobapi = this.getUrl('/api/blob/'+container+'/'+startswith);
        const response = await fetch(blobapi);
        const bloblist = await response.json();
        return bloblist;    
    },
    dynamicContentSort: function (a,b) {
        var result = (a.order < b.order) ? -1 : (a.order > b.order) ? 1 : 0;
        return result;
    },
    sortByPosition: function(a,b){
        var posA = parseInt(a.position);
        var posB = parseInt(b.position);
        var result = (posA < posB) ? -1 : (posA > posB) ? 1 : 0;
        return result;
    },
    getSasUrl: async function(container, blob){
        const sasapi = this.getUrl('/api/sasurl/'+container+'/'+blob);
        const response = await fetch(sasapi);
        const sasUrl = await response.json();
        return sasUrl;
    },
    setmetadescription: function(desc){
        this.setheadtag('meta', 'name',     'description',    'content', desc);
        this.setheadtag('meta', 'property', 'og:description', 'content', desc);
        this.setheadtag('meta', 'name', 'twitter:description', 'content', desc);
    },
    setthumbnail: async function(imgurl, useFullUrl){
        var conf = await this.getConfig();
        var rooturlforseo = conf.rooturlforseo;

        if (imgurl){
            if (!useFullUrl){
                imgurl = rooturlforseo+'/api/image/'+imgurl;
            }else{
                imgurl = rooturlforseo+'/'+imgurl;
            }
        }
        this.setheadtag('meta', 'name',     'thumbnail', 'content', imgurl);
        this.setheadtag('meta', 'property', 'og:image',  'content', imgurl);
        this.setheadtag('meta', 'property', 'og:image:secure_url',  'content', imgurl);
        this.setheadtag('meta', 'name',     'twitter:image', 'content', imgurl);

    },
    settitle:function(title){
        document.title = title;
        this.setheadtag('meta', 'property', 'og:title',  'content', title);
        this.setheadtag('meta', 'name', 'twitter:title',  'content', title);

    },
    setheadtag: function(tag, selectorattribute, selectorvalue, updatedattribute, value, inContent){
        var selector = tag+'['+selectorattribute+'="'+selectorvalue+'"]';
        var existingtag = document.querySelector(selector);
        if (value){
            if (!existingtag){
                var headtag = document.querySelector('head');
                existingtag = document.createElement(tag);
                existingtag.setAttribute(selectorattribute,selectorvalue)
                headtag.appendChild(existingtag);
            }
            if(inContent){
                existingtag.innerHTML=value;
            }else {
                existingtag.setAttribute(updatedattribute, value);
            }
        } else if (existingtag){
            existingtag.parentElement.removeChild(existingtag);
        }
    },
    setcanonicalpagelink: async function(link, isfulllink){
        if (link && !isfulllink){
            var conf = await this.getConfig();
            var rooturlforseo = conf.rooturlforseo;
            if (link=="/"){
                link = rooturlforseo;
            }else{
                link = rooturlforseo + link;
            }
        }
        this.setheadtag('link','rel','canonical','href',link);
        this.setheadtag('meta', 'property', 'og:url',  'content', link);

    },
    setProductRichData: async function(product){
        var conf = await this.getConfig();
        var rooturlforseo = conf.rooturlforseo;
        var imageurl = rooturlforseo+'/api/image/'+product.seothumbnail;
        var richContent = {
            "@context": "https://schema.org",
            "@type":"Product",
            "name": product.pagetitle,
            "image":[
                imageurl
            ],
            "description":product.seodesc,
            "brand":{
                "@type":"Brand",
                "name":"BackToRelaxation"
            },
            "offers": {
                "@type":"Offer",
                "url": rooturlforseo+"/coaching/"+product.url,
                "priceCurrency":"EUR",
                "price":product.price,
                "availability":"https://schema.org/onlineOnly"
            }
        } ;
        var richContentString = JSON.stringify(richContent);
        this.setheadtag("script", "type","application/ld+json","",richContentString, true);
    },
    getMenus: async function(){
        var user = await this.getUser();
        var url;
        if (this.isUserAdmin(user)){
            url ='/api/adm/menu'
        }else{
            url ='/api/menu'
        }
        const menuapi = this.getUrl(url);
        const response = await fetch(menuapi);
        const menulist = await response.json();
        return menulist;    
    },
    getConfig: async function(){
        const configapi = this.getUrl('/api/config');
        const response = await fetch(configapi);
        const config = await response.json();
        return config;
    },
    noseoinfo: async function(){
        this.setmetadescription();
        await this.setthumbnail();
        await this.setcanonicalpagelink();

    }
}