<template>
    <div style="clear:both"> 
        <div v-for="(coaching, index) in coachinglist" v-bind:key="coaching.rowKey">
            <coaching v-bind:coaching="coaching" v-bind:user="user" v-bind:even="index % 2 === 0"/>
 
        </div>
    </div>
</template>

<script>
import coaching from "./MonCoachingInList.vue";

export default {
    data: function(){
        return {
            
        }
    },
    props:{
        coachinglist: {
            type:Array
        },
        user:{
            type:Object
        }
    },
    components: {
        coaching
    }
}
</script>