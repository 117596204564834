<template>
    <div>
        <div class="row">
            <div class="col-25">
                <label for="fsource">Source</label>
            </div>
            <div class="col-75">
                <blobpicker id="fsource" v-model="content.source" type="pdf" v-on:input="updateUrl"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fimage">Image</label>
            </div>
            <div class="col-75">
                <blobpicker id="fimage" v-model="content.image" type="images"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fText">Texte du lien</label>
            </div>
            <div class="col-75">
                <input id="fText" type="text" v-model="content.text">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fView">Aperçu</label>
            </div>
            <div class="col-75">
                <pdfview :content="content" />
            </div>
        </div>
    </div>
</template>

<script>
import pdfview from './DynamicPdfView.vue';
import blobpicker from './BlobPicker.vue';
import tool from '../assets/js/tool.js';

export default {
    props:{
        content: {
            type:Object
        }
    },
    components:{
        pdfview,
        blobpicker
    },
    methods:{
        updateUrl: async function(){
            var data = await tool.getSasUrl("pdf", this.content.source);
            this.content.calculatedPdfUrl = data.url;
        }
    },
    created: function(){

    }
}
</script>

<style scoped>


</style>