<template>
  <div id="napp" class="b-clr1">
    <div id="top">
      <nav>
          <defmenu v-bind:user="user"></defmenu>
      </nav>
      <header>
        <!-- /* <defheader v-bind:user="user"></defheader>*/ -->
      </header>
    </div>
    <router-view id="view"/>
    <deffooter></deffooter>
  </div>
</template>

<script>
import defmenu from "./components/Menu.vue";
// import defheader from "./components/DefHeader.vue";
import deffooter from "./components/DefFooter.vue";
import tool from "./assets/js/tool.js"; 

export default {
    data() {
        return {
            user: null
        }
    },
    methods:{
        getUser: async function(){
            return await tool.getUser();
        },
    },
    components: {
        defmenu,
        // defheader,
        deffooter
    },
    created:async function(){
        this.user = await tool.getUser();
    }
}
</script>

<style>

    body {
        font-family: Montserrat, sans-serif;
        margin:0px;

    }

    #view {
      max-width:1600px;
      margin-right:auto;
      margin-left:auto;
      padding-top:0px;
    }

    #top {
      min-height:150px;
      z-index: 20;
    }

    .txt-clr0{
      color:white
    }

    .txt-clr1{
      color: #EBF7E3
    }

    .txt-clr2{
      color:#9BD770
    }

    .txt-clr3{
      color:#66B032
    }

    .txt-clr4{
      color:#375F1B
    }

    .txt-clr5{
      color:#1B3409
    }

    .txt-clr6{
      color:black
    }

    .htxt-clr0:hover{
      color:white
    }

    .htxt-clr1:hover{
      color: #EBF7E3
    }

    .htxt-clr2:hover{
      color:#9BD770
    }

    .htxt-clr3:hover{
      color:#66B032
    }

    .htxt-clr4:hover{
      color:#375F1B
    }

    .htxt-clr5:hover{
      color:#1B3409
    }

    .htxt-clr6:hover{
      color:black
    }
    
    .hb-clr0:hover{
      background-color: white
    }

    .hb-clr1:hover{
      background-color: #EBF7E3
    }

    .hb-clr2:hover{
      background-color:#9BD770
    }

    .hb-clr3:hover{
      background-color:#66B032
    }

    .hb-clr4:hover{
      background-color:#375F1B
    }

    .hb-clr5:hover{
      background-color:#1B3409
    }

    .hb-clr6:hover{
      background-color:black
    }

    .b-clr0{
      background-color: white
    }

    .b-clr1{
      background-color: #EBF7E3
    }

    .b-clr1-trans {
        background-color: rgba(235, 247, 227, 0.9)
    }

    .b-clr2{
      background-color:#9BD770
    }

    .b-clr3{
      background-color:#66B032
    }

    .b-clr4{
      background-color:#375F1B
    }

    .b-clr5{
      background-color:#1B3409
    }

    .b-clr6{
      background-color:black
    }

    @media screen and (max-width: 768px) {
      #top {
        min-height:125px;
      }
    }
</style>