import { render, staticRenderFns } from "./DynamicHtmlEdit.vue?vue&type=template&id=0d87867c&scoped=true"
import script from "./DynamicHtmlEdit.vue?vue&type=script&lang=js"
export * from "./DynamicHtmlEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d87867c",
  null
  
)

export default component.exports