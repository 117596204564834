<template>
    <div v-if="isUserAdmin">        
        <categoryedit  v-bind:category="category" v-on:save="saveCategory" v-on:close="closeCategory"></categoryedit>
        <Loader v-bind:loaderenabled="loaderenabled"/>
    </div>
</template>
<script>
import tool from "../assets/js/tool.js";
import categoryedit from '../components/CategoryEdit.vue';
import Loader from '../components/Loader.vue';

export default {
    data: function(){
        return {
              category: null,
              loaderenabled: false          
        }
    },
    title: 'Edit a category | Coaching Back To Relaxation',
    props: {
        user: Object
    },
    components:{
        categoryedit,
        Loader
    },
    computed: {
        isUserAdmin: function(){
            return tool.isUserAdmin(this.user)
        }
    },
    methods : {
        fetchCategory: async function(){
            if (this.$route.name =='EditCategory' && this.$route.params.name){
                 try {
                    this.loaderenabled = true;
                    let categoryname = this.$route.params.name;
                    this.category = await tool.getCategoryForAdmin(categoryname);
                    this.loaderenabled = false;
                } catch {
                    this.loaderenabled = false;
                }
            }
            else if (this.$route.name == 'NewCategory'){
                this.category = {
                    RowKey: "",
                    PartitionKey: "Category",
                    title:"",
                    shortdescription:"",
                    longdescription:"",
                    seodesc:"",
                    seothumbnail:"",
                    pictureUrl:"",
                    published:false,
                    sortOrder:0,
                    isdarktitle:false
                }
            }
        },
        saveCategory: async function(target, close){
            this.loaderenabled = true;
            let self = this;
            await tool.saveCategoryForAdmin(this.category);
            self.loaderenabled = false;
            if (close){
                self.$router.push({name:'EditCategoryList'});
            }
        },
        closeCategory: function(){
            this.$router.push({name:'EditCategoryList'});
        },
        routeUpdated: async function(){
            await this.fetchCategory();
            await tool.noseoinfo();
        }
    },
    created: async function(){
        await this.fetchCategory();
        await tool.noseoinfo();
    },
    watch: {
        // call again the method if the route changes
        '$route': 'routeUpdated'
    },
}
</script>