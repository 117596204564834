<template>
  <div class="padding b-clr2">
      <div :class="containerclass">
          {{content.source}}
      </div>
  </div>
</template>

<script>
export default {
    props:{
        content: {
            type:Object
        }
    },
    computed:{
        containerclass: function(){
            var res = "";
            if (this.content.isCentered){
                res +=" centered";
            }
            if(this.content.isDark){
                res +=" b-clr4 txt-clr2 padding";
            } else {
                res +=" b-clr2 txt-clr4";
            }
            return res;
        }
    }
}
</script>

<style scoped>
.padding {
    padding:20px;
}
.centered {
    text-align: center;
}

</style>