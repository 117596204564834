<template>
    <div style="clear:both"> 
        <div v-if="categories && categories.length>0" class="table  b-clr5">
            <div v-for="(cat, index) in categories" v-bind:key="cat.rowKey" class="row">
                <div class="content">
                    <category v-bind:category="cat" 
                                v-bind:hasEdit="false"
                                v-bind:even="index % 2 === 0" 
                                />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import category from './CategoryInList.vue'

export default {
    data: function(){
        return {
            
        }
    },
    props:{
        categories: {
            type:Array
        }
    },
    components: {
        category
    }
}
</script>

<style scoped>
    .table {
        display:table
    }
    .row {
        /* padding: 12px 12px;
        box-shadow:0 4px 20px 0; */
        display: table-row;
    }

    
    .content {
        /* padding: 10px;
        box-shadow:0 4px 20px 0; */
    }

    .content:after {
        /* content: ""; */
        clear: both;
        display: table;
    }

    @media screen and (max-width: 768px){
        .table {
            display:block;
        }
        .row {
            display:block;
        }

    }
</style>