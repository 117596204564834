<template>
    <div :class="calculatedClass" @click="switchOpen">
        <div v-if="isOpened">&times;</div>
        <div v-else>
            <slot>
                <i class="fa fa-bars"></i>
            </slot>
        </div>
    </div>
</template>
<script>
export default {
    // data: function(){
    //         return {
    //             opened:false
    //         }
    // },
    emits: [
        'burgerclick'
    ],
    methods: {
        switchOpen: function(){
            this.opened = !this.opened;
            this.$emit('burgerclick',this.opened);
        }
    },
    computed:{
        isOpened: function(){
            return this.opened;
        },
        calculatedClass:function(){
            return this.isMain ? "above main txt-clr0 b-clr5 hb-clr4":"below main txt-clr0 b-clr5 hb-clr4";
        }
    },
    props:{
        isMain:Boolean,
        opened:Boolean

    }
}
</script>
<style scoped>
.main{
    display:inline-block;
    height:48px;
    width:auto;
    font-size: 36px;
    vertical-align: middle;
    border:none;
    padding-left:10px;
    padding-right:10px;
    /* position:relative; */
    
}

.above {
    z-index:100;
    opacity:0.8;
    position:relative;
}

.below {
    z-index:0;
} 
</style>