<template>
    <div class="b-clr2">
        <div  class="pdflink">
            <div class="pdflinkdiv" >
                <a :href="pdfUrl" target="_blank" v-if="content.image">
                    <div class="linkimagediv hb-clr3">
                        <img :src="imageurl" v-if="content.image" class="linkimage"/>
                    </div>
                </a>
            </div>
            <div v-if="content.text" class="linktextdiv">
                    <a :href="pdfUrl" target="_blank" class="txt-clr4 hb-clr3 linktext">
                {{content.text}}
                    </a>
            </div>
        </div>
    </div>
</template>

<script>
import tools from '../assets/js/tool.js'

export default {
    data: function(){
        return {
            calculatedPdfUrl:""
        }
    },
    props:{
        content: {
            type:Object
        }
    },
    methods: {
        newPdfUrl:function(){
            var url = tools.getSasUrl("pdf", this.content.source);
            url.then(
                data => {
                    if(data){
                        this.calculatedPdfUrl = data.url;
                        this.content.calculatedPdfUrl = data.url;
                    }
                }
            )
        }
    },
    computed:{
        imageurl: function(){
            let url = tools.getUrl('/api/image/'+this.content.image);
            return url;
        },
        pdfUrl: function(){
            return this.calculatedPdfUrl;
        },
        calculatedStyle: function(){
            if (this.content.image){
                let url = tools.getUrl('/api/image/'+this.content.image);
                return 'background-image: url('+ url+')'
            } else {
                return '';
            }
        }
    },
    created: function(){
        this.newPdfUrl();
    }
}
</script>

<style scoped>
.pdflink {
    min-width : 300px;
    margin-right: auto;
    margin-left:auto;
    background-position: center;
    background-size: cover;
    overflow:hidden;
}

.pdflinkdiv{
    text-align: center;
    padding: 0px;
    height:100%;
}

.linktextdiv{
    text-align:center; 
    height:40px;
    padding-top:20px;
}
.linktext {
    text-decoration: none;
    text-transform: none;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    width:100%;
}


.linkimagediv{
    height: 100%;
    padding:10px;
    margin-left: 10px;
    margin-right: 10px;
}


.linkimage{
    height: 100%;
    width:100%; 
}

</style>