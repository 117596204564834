<template>
    <div>
       <category v-bind:categories="availablecategories">

        </category>

        <loader v-bind:loaderenabled="loaderenabled"/>
    </div>
</template>
<script>

import tool from "../assets/js/tool.js";
import category from "../components/CategoryList.vue";
import loader from '../components/Loader.vue';

export default {
    data: function(){
        return {
            availablecategories:[],
            loaderenabled: true,
        }
    },
    title: 'Boutique | Coaching Back To Relaxation',
    components: {
        category,
        loader,
    },
    props: {
        user: Object
    },
    methods : {
        fetchCategories: async function(){ 
            this.loaderenabled= true;
            this.availablecategories = await tool.getCategories(true);
            this.loaderenabled= false;
        },
        reloadAll: async function(){
            this.fetchCategories();
            tool.settitle('Boutique | Coaching Back To Relaxation');
            tool.setthumbnail('logo.jpg',true);
            tool.setmetadescription('Le site des coachings de \'Back to Relaxation\'. Découvrez notre offre pour répondre à vos problèmes de stress, d\'angoisse, d\'anxiété et d\'insomnie.');
            await tool.setcanonicalpagelink('/');
        }

    },
    computed: {
        currentUser: function(){
            return this.user;
        },
    },
    created : function(){
        this.reloadAll();
    },
    watch: {
        // call again the method if the route changes
        '$route': 'reloadAll'
    },
}
</script>

<style scoped>
.maintitle{
    width:100%;
    vertical-align: middle;
    text-align: center;
    font-size: 48px;
    font-weight:700;
    padding-top:75px;
    padding-bottom:75px;
}
@media screen and (max-width: 1200px) and (min-width: 769px){
    .maintitle{
        font-size: 30px;
        padding-top:40px;
        padding-bottom:40px;
    }
}
@media screen and (max-width: 768px){
    .maintitle{
        font-size: 20px;
        padding-top:20px;
        padding-bottom:20px;
    }
}
</style>