import { render, staticRenderFns } from "./DynamicTextView.vue?vue&type=template&id=62c0369f&scoped=true"
import script from "./DynamicTextView.vue?vue&type=script&lang=js"
export * from "./DynamicTextView.vue?vue&type=script&lang=js"
import style0 from "./DynamicTextView.vue?vue&type=style&index=0&id=62c0369f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c0369f",
  null
  
)

export default component.exports