<template>
   <div class="container b-clr1-trans" v-if="coaching!=null">
        <div class="row">
            <div class="col-25">
                <label for="frowkey">Row Key</label>
            </div>
            <div class="col-75">
                <input type="text" id="frowkey" placeholder="Rowkey..." v-model="coaching.RowKey" disabled>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="ftitle">Titre</label>
            </div>
            <div class="col-75">
                <input type="text" id="ftitle" placeholder="Titre..." v-model="coaching.title">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="ftitlepage">Titre de page</label>
            </div>
            <div class="col-75">
                <input type="text" id="ftitlepage" placeholder="Titre de page..." v-model="coaching.pagetitle">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="ftitlebreadcrumb">Titre fil ariane</label>
            </div>
            <div class="col-75">
                <input type="text" id="ftitlebreadcrumb" placeholder="Titre fil d'arianne..." v-model="coaching.breadcrumbtitle">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="furl">Ancre <span style="color:red;">( Nom sans espace, ni majuscule, ni caractère spéciaux et unique !!)</span></label>
               
            </div>
            <div class="col-75">
                <input type="text" id="furl" placeholder="Nom sans espace, ni majuscule, ni caractère spéciaux et unique" v-model="coaching.url">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label> Test Ancre</label>
            </div>
            <div class="col-75">
                <a :href="'/coaching/'+coaching.url" target="_blank"  class="txt-clr0 b-clr3 hb-clr4 displaybutton">Afficher dans Page</a>
                <a :href="'/stop/'+coaching.category+'#'+coaching.url" target="_blank"  class="txt-clr0 b-clr3 hb-clr4 displaybutton">Afficher Dans Liste</a>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fcat">Category</label>
            </div>
            <div class="col-75">
                <categoryselector name="fcat" v-model="coaching.category"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fposition">Position</label>
            </div>
            <div class="col-75">
                <input type="number" id="fposition" placeholder="Position dans la categorie" v-model="coaching.position" >
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fseodesc">Description pour Google</label>
            </div>
            <div class="col-75">
                <textarea type="text" id="fseodesc" placeholder="Description..." style="height:100px" v-model="coaching.seodesc"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fseothumbnail">Vignette pour Google<span style="color:red;">800px de large max</span></label>
            </div>
            <div class="col-75">
                <blobpicker id="seothumbnail" placeholder="Vignette..." v-model="coaching.seothumbnail" type="images"/>
                <img v-bind:src="seothumbnail">
            </div>
        </div>

        <hr/>
        <b>Présentation dans liste</b>
        <hr/>

        <div class="row">
            <div class="col-25">
                <label for="fsmallpictureUrl">Image dans la liste (800 pixels de large max, 500-600 dans l'idéal, rapport 16/9eme)</label>
            </div>
            <div class="col-75">
                <blobpicker id="fsmallpictureUrl" type="images" v-model="coaching.smallpictureUrl" placeholder="Petite Image..."/>
                <img v-bind:src="smallpicture">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fsmallpictureAlt">Texte decrivant l'image (alt : pour les malvoyants et le seo)</label>
            </div>
            <div class="col-75">
                <input type="text" id="fsmallpictureAlt" placeholder="Alternate text de l'image..." v-model="coaching.smallpicturealt">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fismini">Affiche l'image telle quelle dans la page de vente</label>
            </div>
            <div class="col-75">
                <input id="fismini" type="checkbox" v-model="coaching.ismini"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fshortdesc">Description courte</label>
            </div>
            <div class="col-75">
                <textarea id="fshortdesc" placeholder="Description courte..." style="height:100px" v-model="coaching.shortdescription"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fshortdescview">Description courte : Apparence</label>
            </div>
            <div class="col-75">
                <div id="fshortdescview" v-html="coaching.shortdescription"></div>
            </div>
        </div>

        <div class="row">
            <div class="col-25">
                <label for="fprice">Prix</label>
            </div>
            <div class="col-75">
                <input type="number" id="fprice" placeholder="Prix..." v-model="coaching.price">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fpricebeforediscount">Prix Avant Promo</label>
            </div>
            <div class="col-75">
                <input type="number" id="fpricebeforediscount" placeholder="Prix avant discount (0 pour cacher)" v-model="coaching.pricebeforediscount">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fnoprice">Html quand pas de prix</label>
            </div>
            <div class="col-75">
                <textarea id="fnoprice" placeholder="'Sur devis' ou 'Gratuit'" v-model="coaching.alternatepriceblock" rows="10"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fhaspaypal">Paypal</label>
            </div>
            <div class="col-75">
                <input type="checkbox" id="fhaspaypal" v-model="coaching.hasPaypal">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fhasdoctolib">Doctolib</label>
            </div>
            <div class="col-75">
                <input type="checkbox" id="fhasdoctolib" v-model="coaching.hasDoctolib">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fusetabforvideo">Utiliser des onglets pour les videos</label>
            </div>
            <div class="col-75">
                <input type="checkbox" id="fusetabforvideo" v-model="coaching.useTabForVideo">
            </div>
        </div>

        <div class="row">
            <div class="col-25">
                <label for="flongdesc">En savoir plus</label>
            </div>
            <div class="col-75">
                <textarea id="flongdesc" placeholder="Longue description..." style="height:200px" v-model="coaching.longdescription"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="flongdescview">En savoir plus : Apparence</label>
            </div>
            <div class="col-75">
                <longdesc id="flongdescview" :longdescription="coaching.longdescription"></longdesc>
            </div>
        </div>
        
        <hr/>
        <b>Cartouche commun à la présentation et au produit</b>
        <hr/>

        <div class="row">
            <div class="col-25">
                <label for="fcrt1">Cartouche : Premiere ligne</label>
            </div>
            <div class="col-75">
                <input type="text" id="fcrt1" v-model="coaching.cartouchefirsttext"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fcrt2">Cartouche : Deuxieme ligne</label>
            </div>
            <div class="col-75">
                <input type="text"  id="fcrt2" v-model="coaching.cartouchesecondtext"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fcrt3">Cartouche : Troisieme ligne</label>
            </div>
            <div class="col-75">
                <input type="text" id="fcrt3" v-model="coaching.cartouchethirdtext"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fcrtsmallpictureUrl">Cartouche : Petite Image</label>
            </div>
            <div class="col-75">
                <blobpicker id="fcrtsmallpictureUrl" placeholder="Petite Image..." v-model="coaching.cartoucheimg" type="images"/>
                <img v-bind:src="crtsmallpicture">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fcrt4">Cartouche : Premiere ligne principale</label>
            </div>
            <div class="col-75">
                <input type="text"  id="fcrt4" v-model="coaching.cartouchemaintextline1"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fcrt5">Cartouche : Seconde ligne principale</label>
            </div>
            <div class="col-75">
                <input type="text" id="fcrt5" v-model="coaching.cartouchemaintextline2"/>
            </div>
        </div>   
        <div class="row">
            <div class="col-25">
                <label for="fcrtvisu">Cartouche : Visuel</label>
            </div>
            <div class="col-75 cartouchevisu" v-bind:style="'background-image: url('+smallpicture+'); max-width:600px; min-height:255px; min-width:450px'">
                <textonimage id="fcrtvisu" :coaching="coaching"/>
            </div>
        </div>    



        <hr/>
        <b>Le produit</b>
        <hr/>
        <div class="row">
            <div class="col-25">
                <label for="fintro">Introduction</label>
            </div>
            <div class="col-75">
                <textarea id="fintro" placeholder="Intro..." style="height:100px" v-model="coaching.introduction"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fintroview">Introduction : Apparence</label>
            </div>
            <div class="col-75">
                <div id="fintroview" v-html="coaching.introduction"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fispprodmini">Affiche l'image telle quelle dans la page du produit</label>
            </div>
            <div class="col-75">
                <input id="fisprodmini" type="checkbox" v-model="coaching.isprodmini"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fpictureUrl">Grande Image</label>
            </div>
            <div class="col-75">
                <blobpicker type="images" id="fpictureUrl" placeholder="Grande Image..." v-model="coaching.pictureUrl"/>
                <div v-if="coaching.isprodmini" class="col-75 ">
                    <img :src="largepicture"/>
                </div>
                <div v-else class="col-75 cartouchevisu" v-bind:style="'background-image: url('+largepicture+'); width:100%; height:263px'">
                    <textonimage id="fcrtvisu" :coaching="coaching"/>
                </div>
                
            </div>
        </div>

        <hr/>
        <b>Les vidéos du produit</b>
        <hr/>

        <div v-for="avideo in computedvideos" v-bind:key="avideo.RowKey" class="videocontainer b-clr3">
            <videoedit v-bind:video="avideo" v-on:delete="deleteVideo"></videoedit>
        </div>
        <div class="row">
            <input type="submit" value="Ajouter une video" v-on:click="newVideo" class="hasmargin txt-clr0 b-clr3 hb-clr4">
        </div>

        <hr/>
        <b>Le contenu du produit (fonctionalité qui remplacera tout à terme)</b>
        <hr/>

        <dynamicContentListEdit :dynamicContentList="coaching.dynamicContent" />

        <hr/>
        <b>Le footer du produit</b>
        <hr/>

        <div class="row">
            <div class="col-25">
                <label for="ffooter">Footer</label>
            </div>
            <div class="col-75">
                <textarea id="ffooter" placeholder="Footer..." style="height:100px" v-model="coaching.footer"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-25">
                <label for="ffooterview">Footer : Apparence</label>
            </div>
            <div class="col-75">
                <div id="ffooterview" v-html="coaching.footer"></div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-25">
                <label for="fpublished">Publi&eacute;</label>
            </div>
            <div class="col-75">
                <input type="checkbox" id="fpublished" v-model="coaching.published">
            </div>
        </div>
        <div class="row">
            <router-link :to="{name:'MyCoaching', params: {id:coaching.RowKey}}" target="_blank"  class="txt-clr0 b-clr3 hb-clr4 displaybutton">Afficher</router-link>
            <input type="submit" value="Achat" v-on:click="ForceAchat()" class="txt-clr0 b-clr3 hb-clr4">           
            <input type="submit" value="Enregistrer" v-on:click="$emit('save', $event.target.value,false)" class="txt-clr0 b-clr3 hb-clr4">
            <input type="submit" value="Enregistrer et fermer" v-on:click="$emit('save', $event.target.value,true)" class="txt-clr0 b-clr3 hb-clr4">
            <input type="submit" value="Fermer" v-on:click="$emit('close', $event.target.value)" class="txt-clr0 b-clr3 hb-clr4">
        </div>

   </div> 
</template>
<script>
import tool from "../assets/js/tool.js";
import videoedit from "./VideoEdit.vue";
import textonimage from "./TextOnImage.vue";
import categoryselector from "./CategorySelector.vue";
import dynamicContentListEdit from "./DynamicContentListEdit.vue";
import blobpicker from "./BlobPicker.vue";
import longdesc from "../microcomponents/longdesc.vue"

export default {
    data: function(){
        return {
            videos:[],
            videosLoaded: false            
        }
    },
    emits: [
        "save",
        "close"
    ],
    props:{
        coaching: {
            type:Object
        }
    },
    methods: {
        newVideo : function(){
            let idPrefix =this.coaching.RowKey;
            let newId = this.getNewVideoId();
            let video = {
                RowKey: idPrefix+"_"+newId,
                PartitionKey:this.coaching.RowKey,
                id:newId,
                title:"",
                position : 0,
                videourl:"",
                mainPictureUrl:"",
                videoPictureUrl:"",
                description:"",
                preambule:"",
                tabgroup:"",
                tablabel:""

            }
            this.videos.push(video);
        },
        deleteVideo:function(deletedVideo){
            for(var avid in this.videos){
                if (this.videos[avid].RowKey==deletedVideo.RowKey){
                    this.videos.splice(avid,1);
                    break;
                }
            }
        },
        getNewVideoId:function(){
            let result = 1;
            let viditem = null
            for (viditem of this.videos){
                let vid = parseInt(viditem.id)
                if(vid >= result){
                    result = vid+1;
                }
            }
            return result;
        },
        ForceAchat:async function(){
            let user = await tool.getUser();
            let ukey = tool.getUserKey(user);
            let coachingId = this.coaching.RowKey;
            let url = tool.getUrl("/api/achats/"+ukey);
            let body = JSON.stringify({
                    coachingid:coachingId
                });
            fetch(url,{
                method:'POST',
                body: body
            })
        },
        fetchvideo: function(){
            let self = this;
            if (this.coaching){
                const coachId = this.coaching.RowKey;
                fetch(tool.getUrl("/api/videometa/"+coachId))
                .then(
                    response => response.json()
                ).then(
                    data => {
                        self.videos = data.sort(tool.sortByPosition);
                        self.videosLoaded = true;
                    }
                )
            }
        }
    },
    computed:{
        smallpicture: function(){
            if(this.coaching.smallpictureUrl)
                return tool.getUrl('/api/image/'+this.coaching.smallpictureUrl);
            else
                return "/logo.jpg";
        },
        largepicture: function(){
            if(this.coaching.pictureUrl)
                return tool.getUrl('/api/image/'+this.coaching.pictureUrl);
            else
                return "/logo.jpg";    
        },
        crtsmallpicture: function(){
            return tool.getUrl('/api/image/'+this.coaching.cartoucheimg);
        },
        computedvideos : function(){
            if (this.videos.length==0 && !this.videosLoaded){
                this.fetchvideo();           
            }
           
            return this.videos;
        }
        
    },
    created:function(){
        this.fetchvideo();
    },
    components:{
        videoedit,
        textonimage,
        categoryselector,
        dynamicContentListEdit,
        blobpicker,
        longdesc
    },
    watch: {
        // call again the method if the route changes
        '$route': 'fetchvideo'
    },
}
</script>

<style scoped>
/* Style inputs, select elements and textareas */
input[type=text], input[type=number], select, textarea{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.hasmargin {
    margin:10px;
}

/* Style the label to display next to the inputs */
label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

/* Style the submit button */
input[type=submit], .displaybutton {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  float: right;
  text-decoration: none;
  font-size: 14px;

}

/* Style the container */
.container {
  /* border-radius: 5px; */
  /* opacity: 0.9; */
  padding: 20px;
}

.videocontainer {
    /* opacity: 0.9; */
    margin-bottom: 10px;
}

/* Floating column for labels: 25% width */
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

/* Floating column for inputs: 75% width */
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.cartouchevisu{
        min-width:450px;
        order: 1;
        height:255px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        overflow:hidden;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit], .displaybutton {
    width: 100%;
    margin-top: 0;
  }
}
</style>
