<template> 
    <div v-if="isUserAdmin">
        <coachingedit  v-bind:coaching="coaching" v-on:save="saveCoaching" v-on:close="closecoaching"></coachingedit>
        <Loader v-bind:loaderenabled="loaderenabled"/>
    </div>
</template>
<script>
import tool from "../assets/js/tool.js";
import coachingedit from "../components/CoachingEdit.vue"; 
import Loader from '../components/Loader.vue';

export default {
    data: function(){
        return {
              coaching: null,
              loaderenabled: false          
        }
    },
    title: 'Edit Coaching | Coaching Back To Relaxation',
    methods:{
        fetchCoaching: async function(){
            if (this.$route.name =='EditCoaching' && this.$route.params.id){
                try {
                    this.loaderenabled = true;
                    let coachingkey = this.$route.params.id;
                    this.coaching = await tool.getCoachingForAdmin(coachingkey);
                    this.loaderenabled = false;
                } catch {
                    this.loaderenabled = false;
                }
                
            }else  if (this.$route.name == 'NewCoaching'){
                this.coaching = {
                    RowKey: await this.getNewCoachingRowkey(),
                    PartitionKey:"Coaching",
                    title:"",
                    url:"",
                    longdescription:"",
                    seodesc:"",
                    seothumbnail:"",
                    introduction:"",
                    pictureUrl:"",
                    footer:"",
                    shortdescription:"",
                    smallpictureUrl:"",
                    smallpicturealt:"",
                    price:0,
                    pricebeforediscount:0,
                    published:false,
                    cartouchefirsttext:"SE TRANSFORMER",
                    cartouchesecondtext:"PARTICULIERS",
                    cartouchethirdtext:"PACK COUP de",
                    cartoucheimg:"coeur.png",
                    cartouchemaintextline1:"CRISE",
                    cartouchemaintextline2:"D'ANGOISSE",
                    category:""
                    
                }
            }
        },
        saveCoaching: async function(target, close){
            this.loaderenabled = true;
            await tool.saveCoachingForAdmin(this.coaching);
            this.loaderenabled = false;
            if (close){
                this.$router.push({name:'EditCoachingList'});
            }
        },
        closecoaching: function(){
            this.$router.push({name:'EditCoachingList'});
        },
        getNewCoachingRowkey:async function(){
            let availablecoachings= await tool.getCoachingsForAdmin();
            let result = 1;
            let coaching = null;
            for (coaching of availablecoachings){
                let id = parseInt(coaching.RowKey)
                if(id >= result){
                    result = id+1;
                }
            }
            return result.toString();
        },
        routeUpdated: async function(){
            await this.fetchCoaching();
            await tool.noseoinfo();
        }
    },
    props: {
        user: Object
    },
    components: {
        coachingedit,
        Loader
    },
    computed: {
        isUserAdmin: function(){
            return tool.isUserAdmin(this.user)
        }
    },
    created: async function(){
        await this.fetchCoaching();
        await tool.noseoinfo();
    },
    watch: {
        // call again the method if the route changes
        '$route': 'routeUpdated'
    },
}
</script>