<template>
    <div>
        <div class="row">
            <div class="col-25">
                <label for="fsource">source</label>
            </div>
            <div class="col-75">
                <textarea v-model="content.source" placeholder="Texte..." rows="10" ></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fbackground">Sombre</label>
            </div>
            <div class="col-75">
                <input id="fbackground" v-model="content.isDark" type="checkbox"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fcenter">Centr&eacute;</label>
            </div>
            <div class="col-75">
                <input id="fcenter" v-model="content.isCentered" type="checkbox"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label>Aperçu</label>
            </div>
            <div class="col-75">
                <textview :content="content" />
            </div>
        </div>
    </div>
</template>

<script>
import textview from "./DynamicTextView.vue";

export default {
    props:{
        content: {
            type:Object
        }
    },
    components:{
        textview
    }
}
</script>

<style scoped>


</style>