<template>
    <div class="b-clr1" v-if="isUserAdmin">
        <div style="clear:both"> 
            <div v-if="coachinglist && coachinglist.length>0">
                <div v-for="coaching in coachinglist" v-bind:key="coaching.rowKey" class="row b-clr3">
                    <div class="content b-clr0">
                        <coaching v-bind:coaching="coaching" 
                                    v-bind:user="user" 
                                    />
                    </div>
                </div>
            </div>
        </div>

        <Loader v-bind:loaderenabled="loaderenabled"/>
    </div>
</template>
<script>
import tool from "../assets/js/tool.js";
import coaching from "../components/CoachingInListForEdit.vue"
import Loader from '../components/Loader.vue';


export default {
    components: { 
        Loader,
        coaching
    },
    data: function(){
        return {
            coachinglist: [],
            loaderenabled: false,
        }
    },
    title : 'List Coaching for Edition | Coaching Back To Relaxation',
    props: {
        user: Object
    },
    methods:{
        fetchCoachings: async function(){
            let self = this; 
            self.loaderenabled=true;
            self.coachinglist = await tool.getCoachingsForAdmin();
            self.loaderenabled=false; 
        },
        routeUpdated: async function(){
            await this.fetchCoachings();
            await tool.noseoinfo();
        }
    },
    created: async function(){
        await this.fetchCoachings();
        await tool.noseoinfo();
    },
    computed: {
        isUserAdmin: function(){
            return tool.isUserAdmin(this.user)
        }
    },
    watch: {
        // call again the method if the route changes
        '$route': 'routeUpdated'
    }

}
</script>
<style scoped>
    .row {
        padding: 12px 12px;
        box-shadow:0 4px 20px 0;
    }

    
    .content {
        padding: 10px;
        box-shadow:0 4px 20px 0;
    }

    .content:after {
        content: "";
        clear: both;
        display: table;
    }
</style>