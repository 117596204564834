import Vue from 'vue'
import App from './App.vue'
import Home from './pages/Home.vue'
import CoachingEditPage from './pages/CoachingEditPage.vue'
import CoachingListForEdit from './pages/CoachingListForEditPage.vue'
import CategoryEditPage from './pages/CategoryEditPage.vue'
import CategoryListForEdit from './pages/CategoryListForEditPage.vue'
import MyCoachingListPage from './pages/MyCoachingListPage'
import MyCoachingPage from './pages/MyCoachingPage.vue'
import HomeCategory from './pages/HomeCategory.vue'
import Coaching from './pages/Coaching.vue'
import VueRouter from 'vue-router'
import tools from './assets/js/tool.js'
import titleMixin from './mixins/titleMixin.js'

Vue.mixin(titleMixin)
Vue.use(VueRouter)

Vue.config.productionTip = false

var user = null;
tools.getUser().then(
  data => {
    if (data){
      user = data;
    }

  const routes = [ 
    {
      path: '/',
      name: 'Home',
      component: Home,
      props: () => ({
        user: user
      })
    },
    {
      path:'/edit/coaching',
      name: 'EditCoachingList',
      component: CoachingListForEdit,
      props: () => ({
        user: user
      })
    },
    {
      path:'/edit/coaching/:id',
      name: 'EditCoaching',
      component: CoachingEditPage,
      props: () => ({
        user: user
      })
    },
    {
      path:'/edit/newcoaching',
      name: 'NewCoaching',
      component: CoachingEditPage,
      props: () => ({
        user: user
      })
    },
    {
      path: '/edit/categorie', 
      name: 'EditCategoryList',
      component: CategoryListForEdit,
      props: () => ({
        user: user
      })
    },
    {
      path: '/edit/categorie/:name', 
      name: 'EditCategory',
      component: CategoryEditPage,
      props: () => ({
        user: user
      })
    },
    {
      path: '/edit/newcategorie', 
      name: 'NewCategory',
      component: CategoryEditPage,
      props: () => ({
        user: user
      })
    },
    {
      path: '/mesproduits/:id/:chapter?/:video?',
      name: 'MyCoaching',
      component: MyCoachingPage,
      props: () => ({
        user: user
      })
    },
    {
      path: '/mesproduits',
      name: 'MyCoachings',
      component: MyCoachingListPage,
      props: () => ({
        user: user
      })
    },
    {
      path: '/stop/:id',
      name: 'HomeCategory',
      component: HomeCategory,
      props: () => ({
        user: user
      })
    },
    {
      path: '/coaching/:id',
      name:'coaching',
      component: Coaching,
      props: () => ({
        user: user
      })
    }
  ]

  const router = new VueRouter({mode: 'history', routes});

  new Vue({
    render: h => h(App),
    router,
  }).$mount('#app');
})
