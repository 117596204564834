<template>
    <div class="submenu txt-clr5 b-clr1 hb-clr2">
      <a v-if="this.menuitem.ref" :href="this.menuitem.ref" class="submenulink txt-clr5">
          {{this.menuitem.label}}
      </a>
      <a v-else-if="this.menuitem.to" v-on:click="goto(menuitem.to)" class="submenulink txt-clr5">
          {{this.menuitem.label}}
      </a>
    </div>
  </template>
  
  <script>
  export default {
        props:{
            menuitem:Object
        },
        emits: [
            "clicked"
        ],
        methods:{
            generateto:function(orgto){
                if (typeof orgto === "string" ){
                return {name:orgto};
                } else {
                    return orgto;
                }
            },
            goto:function(navto){
                this.$emit("clicked");
                this.$router.push(this.generateto(navto));
            }    
        }
  
    }
  </script>
  
  <style scoped>
  .submenu{
      display: block;
      padding:6px 16px;
  }
  
  .submenulink {
      display: block;
      text-decoration: none;
      cursor:pointer;
  }
  </style>