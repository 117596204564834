<template>
    <div></div>
</template>
<script>
import tool from "../assets/js/tool.js"

export default {
    data() {
        return {
            configuration:null
        }
    },
    emits: [
        "initdone"
    ],
    methods:{
        fetchConfiguration: function(callback){
            let self = this; 
            let url = tool.getUrl("/api/config");
            fetch(url).then(
                response => response.json()
            ).then(
                data => {
                    if(data){
                        self.configuration = data;
                        callback();
                    }
                }
            );                    
        },
        initPaypal: function(){
            let paypalUrl = "https://www.paypal.com/sdk/js?client-id="+this.configuration.paypalid+"&currency=EUR&integration-date=2021-02-23&locale=fr_FR";
            let script = document.createElement('script');
            script.setAttribute('src', paypalUrl);
            script.setAttribute('type','text/javascript');
            script.setAttribute('data-page-type','product-listing');
            script.addEventListener("load", this.setPaypalLoaded);
            document.head.appendChild(script);
        },
        setPaypalLoaded:function(){
            this.$emit("initdone");
        }
    },
    mounted: function(){
        this.fetchConfiguration(this.initPaypal);
    }
}
</script>