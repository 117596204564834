<template>
  <div>
        <div class="row">
            <div class="col-25">
                <label for="fsource">sourceImage</label>
            </div>
            <div class="col-75">
                <blobpicker type="images" v-model="content.source" placeholder="Fichier image..."/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fbackground">Background</label>
            </div>
            <div class="col-75">
                <input id="fbackground" v-model="content.hasBackground" type="checkbox"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="fbackgroundtop">Top Background</label>
            </div>
            <div class="col-75">
                <input id="fbackgroundtop" v-model="content.hasTopBackground" type="checkbox"/>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label>Aperçu</label>
            </div>
            <div class="col-75">
                <imageview :content="content" />
            </div>
        </div>
  </div>
</template>

<script>
import imageview from './DynamicImageView.vue';
import blobpicker from './BlobPicker.vue';

export default {
    props:{
        content: {
            type:Object
        }
    },
    components:{
        imageview,
        blobpicker
    }
}
</script>

<style scoped>

</style>