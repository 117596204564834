<template>
    <div v-bind:class="this.class">
        <div v-bind:style="'background-image: url('+ imageurl+')'" class="dynamicimage">
        </div>
    </div>
</template>

<script>
import tools from '../assets/js/tool.js'

export default {
    props:{
        content: {
            type:Object
        }
    },
    computed:{
        imageurl: function(){
            let url = tools.getUrl('/api/image/'+this.content.source);
            return url;
        },
        class: function(){
            let aclass = "dynamicimageframe";
            if(this.content.hasBackground){
                aclass +=" b-clr2 dynamicimageframepadding";
            }
            if(this.content.hasTopBackground){
                aclass += " dynamicimageframepaddingtop"
            }
            return aclass;
        }
    }

}
</script>

<style scoped>
.dynamicimageframe {
    width:auto;
    height: 300px;

}

.dynamicimageframepadding {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px
}

.dynamicimageframepaddingtop {
    padding-top : 20px;
}

.dynamicimage {
    height: 100%;
    background-position: center;
    background-size: cover;
    overflow:hidden;
}
</style>