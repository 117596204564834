<template>
    <div>
        <div class="breadcrumb b-clr1 txt-clr4">
            <router-link :to="{name: 'Home'}" class="txt-clr4 htxt-clr3">
                <i class="fa fa-home"/> Boutique
            </router-link>
            <i class="fa fa-caret-right"></i>
            Mes produits
        </div>
        <mescoachinglist v-bind:coachinglist="boughtcoachings" v-bind:user="currentUser">
        
        </mescoachinglist>
        <loader v-bind:loaderenabled="loaderenabled"/>
    </div>
</template>
<script>

import tool from "../assets/js/tool.js";

import mescoachinglist from "../components/MesCoachingsList.vue";
import loader from '../components/Loader.vue';

export default {
    data: function(){
        return {
            loaderenabled: true,
            mycoachings:null,
            purchaseloaded:false
        }
    },
    components: {
        loader,
        mescoachinglist,
    },
    props: {
        user: Object
    },
    methods : {
        displayallcoachings: function(){
            this.$router.push({name:'Home'})
        },
        displaymycoachings: function(){
            if(this.$route.name!='MyCoachings')
                this.$router.push({name:'MyCoachings'})
        },
        fetchMyCoachings: async function(){
            this.loaderenabled= true;
            this.mycoachings = await tool.getMyCoachings();
            this.purchaseloaded = true;
            this.loaderenabled= false;
        },
        reloadAll: async function(){
            await this.fetchMyCoachings();
            await tool.noseoinfo();
        },
        pageTitle: function(){
            let tit = 'Mes Coachings | Coaching Back To Relaxation';
            return tit;
        } 

    },
    computed: {
        currentUser: function(){
            return this.user;
        },
        boughtcoachings:function(){   
            return this.mycoachings;
        }
    },
    created : function(){
        this.reloadAll();
    },
    watch: {
        // call again the method if the route changes
        '$route': 'reloadAll'
    },
}
</script>

<style scoped>
    .breadcrumb {
        margin-top: 5px;
        /* margin-bottom: 10px; */
        padding: 3px;
        font-size: 16px;
        text-decoration: none;
        font-weight:600;
    }

    .breadcrumb a {
        text-decoration: none;
        padding-right:5px;
        padding-left: 5px;
    }
    .tablink{
      float: left;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 14px 16px;
      font-size: 17px;
      width: 50%;
    }

    .pack{
      height:200px;
      width:40%;
      min-width:400px;
      background-image: url("/pack.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      float:left;
      margin-right:10px;
      /* margin-bottom: 10px; */
    }

    .topmessage {
      padding:20px;
      margin-bottom: 10px;
      box-shadow:0 4px 20px 0;
      font-size:25px;
      font-style:italic;
      clear:both;
      overflow: auto;
    }

    .smalldesccontainer{
      padding:10px;
      margin-top:10px;
    }

    .smalldesc{
      font-size:14px;
      padding:20px;
      margin:0px;
      box-shadow:0 4px 20px 0;
      font-style:italic;
    }

    @media screen and (max-width: 1300px){
      .pack{
        width:100%;
        float:none;
        min-width:0px;
      }
      .topmessagetext{
        padding-top:10px;
      }
    }
    @media screen and (max-width: 500px){
      .tablink{
        height:70px;
      }
    }
</style>