<template>
    <div>
        <imageView :content="content" v-if="content.type == 'image'" />
        <htmlView :content="content" v-if="content.type == 'html'"/>
        <textView :content="content" v-if="content.type == 'text'"/>
        <pdfView :content="content" v-if="content.type == 'pdf'" />

    </div>
</template>

<script>
import imageView from './DynamicImageView.vue';
import htmlView from './DynamicHtmlView.vue';
import textView from './DynamicTextView.vue';
import pdfView from './DynamicPdfView.vue';

export default {
    props:{
        content: {
            type:Object
        }
    },
    components:{
        imageView,
        htmlView,
        textView,
        pdfView
    }
}
</script>

<style>

</style>