<template>
    <div class="longdesccontainer b-clr1" v-html="this.longdescription">
        
    </div>
</template>
<script>
export default {
    props:{
        longdescription : {
            type:String
        }
    }
}
</script>
<style scoped>
   .longdesccontainer {
        padding: 10px;
        float:none;
        position:relative;
    }

    .longdesccontainer >>> div {
        margin:0 !important;
        padding: 10px;
        box-shadow:none !important;
        font-family: Monserrat, sans-serif !important;
    }
    .longdesccontainer >>> div.b-clr2 {
        background-color: #EBF7E3;
    }

    .longdesccontainer >>> div.b-clr3 {
        background-color: #1B3409;
    }

    .longdesccontainer >>> div:empty {
        height:0px !important;
        padding:0px;
        margin:0px;
    }

    .longdesccontainer >>> ul {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

</style>